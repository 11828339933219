import React, { useEffect, useState } from 'react'
import ObserverFields from '../../Observador/config/ObserverFields'
import tableTitles from '../config/TableTitles'
import '../style/Plantillas.css'

import tortuga from '../../Imagenes/tortuga.png'
import tortuga1 from '../../Imagenes/t1.png'
import tortuga2 from '../../Imagenes/t2.png'
import tortuga3 from '../../Imagenes/t3.png'
import tortuga4 from '../../Imagenes/t4.png'

import { DeployerO } from '../../Observador/components/DeployerO'
import { FormObserver } from '../../Observador/components/FormObserver'

import { DeployerL } from '../../Locacion/components/DeployerL'
import { FormLocation } from '../../Locacion/components/FormLocation'

import { DeployerT } from '../../MoniTablas/components/DeployerT'
import { ContentDeployerC1 } from '../../MoniTablas/MonNocDetHemAni/components/ContentDeployerC1'
import { ContentDeployerC2 } from '../../MoniTablas/MonDiurDetNid/components/ContentDeployerC2'
import { ContentDeployerC3 } from '../../MoniTablas/MonNidEEclo/components/ContentDeployerC3'
import { ContentDeployerC4 } from '../../MoniTablas/MonAreaMarina/components/ContentDeployerC4'

import { ButtonHome } from '../../Home/components/ButtonHome'
import { BuscadorObs } from './BuscadorObs'
import { Cargar } from '../../Sistema/components/Cargar'

export const Plantilla = () => {

    const [loading, setLoading] = useState(false);
    const [disableBL, setDisableBL] = useState(true);
    const [disableBT, setDisableBT] = useState(true);
    const [observer, setObserver] = useState({});
    const [enviado,setEnviado]=useState(0);
    const [disableO,setDisableO]=useState(false);
    const [sObs, setSObs] = useState(0);
    const [busquedaO,setBusquedaO]=useState({});
    const [busquedaOC,setBusquedaOC]=useState({});
    const [disableB,setDisableB]=useState(false);
    const [busquedaSO,setBusquedaSO]=useState({});
    const [moniTablas,setMoniTablas]=useState([]);

    useEffect(() => {

        if (busquedaOC != null && busquedaOC != undefined && Object.entries(busquedaOC).length != 0) {

            setDisableB(true);
        }

    }, [busquedaOC])

    useEffect(() => {

        if (busquedaSO != null && busquedaSO != undefined && Object.entries(busquedaSO).length != 0) {

            setSObs(busquedaSO);
        }

    }, [busquedaSO])
    
    useEffect(() => {
        
        if(enviado!=0){
            
            if (busquedaSO != null && busquedaSO != undefined && Object.entries(busquedaSO).length != 0) {
        
                if(enviado==1)
                    window.scroll({top: document.getElementById("imgt1").offsetTop, behavior: 'smooth'});
                    
                    else if(enviado==2)
                    window.scroll({top: document.getElementById("imgt2").offsetTop, behavior: 'smooth'});
                    
                    else if(enviado==3)
                    window.scroll({top: document.getElementById("imgt3").offsetTop, behavior: 'smooth'});

                    else if(enviado==4)
                    window.scroll({top: document.getElementById("imgt4").offsetTop, behavior: 'smooth'});

            }else{
                document.getElementsByClassName('p_button').item(enviado-1).focus();
            }

            setEnviado(0);
        }

    }, [enviado])
    
    const handleReset=()=>{

        //console.log('Reset')
        
        setLoading(true);
        window.location.reload();
        setTimeout(()=>{
            
            setLoading(false);
        },3000);

    };

    if (loading) {
        return <Cargar />
    } else {
        return (
            <div className='container p-1'>
                <br />
                <h1>Planilla de Registro de Datos de Tortugas Marinas</h1>
                <div className='row'>
                    <div className='d-grid col-6'>
                        <ButtonHome />
                    </div>
                    <div className='d-grid col-6'>
                        <button id='button-refresh' className='btn btn-success mt-2 mb-4 fs-5' onClick={handleReset}>
                            <i className="fa-solid fa-arrows-rotate"></i>
                            Reset
                        </button>
                    </div>
                </div>
                <br />
                <div className='pimagen-tortuga'>
                    <img src={tortuga} />
                </div>
                <BuscadorObs disableB={disableB} setBusquedaO={setBusquedaO} />
                <DeployerO busquedaO={busquedaO}
                            setDisableO={setDisableO}
                            setBusquedaOC={setBusquedaOC}
                            titulo={tableTitles[0].titulo}
                            contentDeployer={<FormObserver busquedaOC={busquedaOC}
                                                titulo={tableTitles[0]}
                                                campos={ObserverFields}
                                                setDisableBL={setDisableBL}
                                                setObserver={setObserver}
                            />}
                    disableO={disableO}
                />
                <DeployerL busquedaO={busquedaO}
                    setBusquedaSO={setBusquedaSO} 
                    titulo={tableTitles[1].titulo}
                    contentDeployer={
                        <>
                            <FormLocation busquedaSO={busquedaSO}
                                setBusquedaSO={setBusquedaSO}
                                setDisableBT={setDisableBT}
                                setSObs={setSObs}
                                observer={observer}
                                setDisableBL={setDisableBL} />
                        </>
                    }
                    contentDeployer2={
                        <>
                            <div className='content-table'>
                                <br />
                                <div className='pimagen-tortuga'>
                                    <img id='imgt1' src={tortuga1} />
                                </div>

                                <DeployerT titulo={tableTitles[2].titulo}
                                           busquedaSO={busquedaSO}
                                           setMoniTablas={setMoniTablas}
                                    contentDeployer={<ContentDeployerC1 sObs={sObs}
                                                                        moniTablas={moniTablas}
                                                                        setEnviado={setEnviado}
                                                                        numtabla={1}
                                                    />}
                                    disableBT={disableBT}
                                />
                            </div>
                            <br />
                            <div className='content-table'>
                                <br />
                                <div id='imgt2' className='pimagen-tortuga'>
                                    <img src={tortuga2} />
                                </div>
                                <DeployerT titulo={tableTitles[3].titulo}
                                           busquedaSO={busquedaSO}
                                           setMoniTablas={setMoniTablas}
                                    contentDeployer={<ContentDeployerC2 sObs={sObs}
                                                                        moniTablas={moniTablas}
                                                                        setEnviado={setEnviado}
                                                                        numtabla={2} 
                                                    />}
                                    disableBT={disableBT}
                                />
                            </div>
                            <br />
                            <div className='content-table'>
                                <br />
                                <div id='imgt3' className='pimagen-tortuga'>
                                    <img src={tortuga3} />
                                </div>
                                <DeployerT titulo={tableTitles[4].titulo}
                                            busquedaSO={busquedaSO}
                                            setMoniTablas={setMoniTablas}
                                    contentDeployer={<ContentDeployerC3 sObs={sObs}
                                                                        moniTablas={moniTablas}
                                                                        setEnviado={setEnviado}
                                                                        numtabla={3} 
                                                    />}
                                    disableBT={disableBT}
                                />
                            </div>
                            <br />
                            <div className='content-table'>
                                <br />
                                <div id='imgt4' className='pimagen-tortuga'>
                                    <img src={tortuga4} />
                                </div>
                                <DeployerT titulo={tableTitles[5].titulo}
                                            busquedaSO={busquedaSO}
                                            setMoniTablas={setMoniTablas}
                                    contentDeployer={<ContentDeployerC4 sObs={sObs}
                                                                        moniTablas={moniTablas}
                                                                        setEnviado={setEnviado}
                                                                        numtabla={4} 
                                                    />}
                                    disableBT={disableBT}
                                />
                            </div>
                            <br />
                        </>
                    }
                    disableBL={disableBL}
                    setDisableO={setDisableO}
                />
                <div className="alert alert-success" role="alert" >
                    <a href="https://monitoreomarinocosterord.com/index.php/tortugas-marinas/"
                        target="_blank" className="link-success" >
                        Enterate más sobre el Monitoreo de Tortugas Marinas
                    </a>
                </div>
            </div>
        )
    }
}
