import React from 'react'
import titulos from '../config/T1HeadersFields'
import '../../MoniTablas/MonNocDetHemAni/style/MonNocDetHemAni.css'

export const MonNocDetHemAni = ({tabla1}) => {
    return (
        <div>
            {
                tabla1 != null && Object.entries(tabla1).length != 0 ?
                    <div className='show-scroll'>
                        <table id='mndha-table' className="table table-bordered">
                            <thead>
                                <tr>
                                    {
                                        titulos.map((elem,index) => {

                                            return (
                                               
                                                    <td key={index+'hmndha'} >{elem.title1}</td>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tabla1.map((elem)=>{

                                    return(
                                        <tr>
                                            <td key={elem.id+'c1mndha'} >{elem.hora}</td>
                                            <td key={elem.id+'c2mndha'} >{elem.actividad}</td>
                                            <td key={elem.id+'c3mndha'} >{elem.latitud}</td>
                                            <td key={elem.id+'c4mndha'} >{elem.longitud}</td>
                                            <td key={elem.id+'c5mndha'} >{elem.num_nido}</td>
                                            <td key={elem.id+'c6mndha'} >{elem.especie}</td>
                                            <td key={elem.id+'c7mndha'} >{elem.morfometria_lscc}</td>
                                            <td key={elem.id+'c8mndha'} >{elem.morfometria_acc}</td>
                                            <td key={elem.id+'c9mndha'} >{elem.estado_fisico}</td>
                                            <td key={elem.id+'c10mndha'} >{elem.ancho_cabeza}</td>
                                            {/*<td key={elem.id} >{elem.zona_balizas}</td>*/}
                                            <td key={elem.id+'c11mndha'} >{elem.num_huevos}</td>
                                            <td key={elem.id+'c12mndha'} >{elem.nido_perfil}</td>
                                            <td key={elem.id+'c13mndha'} >{elem.temp_nido}</td>
                                            <td key={elem.id+'c14mndha'} >{elem.prof_nido}</td>
                                            <td key={elem.id+'c15mndha'} >{elem.marca_tags}</td>
                                            <td key={elem.id+'c16mndha'} >{elem.observaciones}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <p>Sin Datos</p>
            }
            <br />
    </div >
  )
}
