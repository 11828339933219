import React, { useState } from 'react'
import axios from "axios";

export const BuscadorObs = ({setBusquedaO,disableB}) => {

    //var baseURL = 'http://localhost:8080';
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442";
    var id=0;

    const [search, setSearch] = useState({});
    const [observadores, setObservadores] = useState([]);

    const handleChange = (e) => {

        const { name, value } = e.target;
        
        setSearch({
            ...search,
            [name]: value
        });

        //console.log(search);
    };

    const handleSelect=(e)=>{

        id=e.target.value;
        observadores.map(elem=>{

            if(elem.id == id)
                setBusquedaO(elem);
        });

    };

    const handleSearch = (e) => {
        e.preventDefault();

        if(Object.entries(search).length!=0 && search.bnobs!=''){

            //console.log('Datos a enviar')
            //console.log(search);
    
            axios
                .post(`${baseURL}/observadores/bnobs`, search)
                .then((response) => {
    
                    //console.log(response);
                    setObservadores(response.data);
                    setSearch({});
                    document.getElementById('bnobs').reset();
    
                }).catch(error => { 
                    
                    //console.log(error);
                    setSearch({})
    
                });
            }

    };

    return (
        <div className='row mb-2  p-2'>
            <div className='col-10 mx-auto'>
                <div className='row'>
                    <div className='col-7 '>
                        <form id='bnobs' onSubmit={handleSearch}>
                            <div className='row '>
                                <div className='col-9'>
                                    <input type='text' className='form-control' id='bnobs' name='bnobs'
                                        placeholder='Buscar por nombre de observador' onChange={handleChange} 
                                        disabled={disableB}/>
                                </div>
                                <div className='col-3 d-grid mb-2'>
                                    <button className='btn btn-primary' onClick={handleSearch}
                                            disabled={disableB}>
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='col-5 '>
                        <select  className="form-select" onChange={handleSelect} disabled={disableB}>
                            {
                                observadores.length == 0 ?
                                    <option defaultValue>Sin Resultados</option>
                                    :
                                    <>
                                    <option defaultValue>Seleccionar Observador</option>
                                    {observadores.map((elem, index) => <option key={elem.id} value={elem.id}>{elem.nombre}</option>)}
                                    </>
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
