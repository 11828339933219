export default [

    {
      tipo: "texto",
      campo: "Hora"
    },
    {
      tipo:'ayuda',
      mensaje:'Especie: tortuga verde o blanca (Chelonia mydas), '+
              'el carey (Eretmochelys imbricata), caguama o caguamo (Caretta caretta), '+
              'tortuga golfina (Lepidochelys olivacea), el tinglar, (Dermochelys coriacea)'
    },
    {
      tipo: "texto",
      campo: "Especie"
    },
    {
      tipo: "titulo1",
      campo: "Observada / Capturada"
    },
    {
      tipo: "texto",
      campo: "Observada_Capturada"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    },
    {
      tipo:'ayuda',
      mensaje:'Actividad: for: forrajeo, ap: apareamiento, '+
              'des: desplazamiento, rep: reposo'
    },
    {
      tipo: "texto",
      campo: "Actividad"
    },
    {
      tipo: "titulo1",
      campo: "Talla Estimada"
    },
    {
      tipo: "texto",
      campo: "Talla_estimada"
    },
    {
      tipo: "titulo1",
      campo: "Morfometría LSCC Largo curvo estándar del carapacho"
    },
    {
      tipo: "texto",
      campo: "Morfometria_LSCC"
    },
    {
      tipo: "titulo1",
      campo: "Morfometría ACC Ancho curvo del carapacho"
    },
    {
      tipo: "texto",
      campo: "Morfometria_ACC"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    },
    {
      tipo: "texto",
      campo: "Sexo"
    },
    {
      tipo: "titulo1",
      campo: "Número Marcas o Tags"
    },
    {
      tipo: "texto",
      campo: "Num_marcas_tags"
    },
    {
      tipo: "titulo1",
      campo: "Estado físico de la tortuga o individuo capturado o daño presente"
    },
    {
      tipo:'ayuda',
      mensaje:'cic: cicatrices, pr: propelazos, her: heridas, mor: mordidas, fib: fibropapilomas, '+
              'presencia de tumores (fibropapilo - mas), según el protocolo de fibropa - pilomatosis, '+
              'cicatrices por propelas, heridas por cuerdas y herida con arpón.'
    },
    {
      tipo: "texto",
      campo: "Estado_fisico"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    },
    {
      tipo: "titulo1",
      campo: "Observaciones"
    },
    {
      tipo: "textoArea",
      campo: "Observaciones"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    }

  ]
