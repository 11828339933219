import React from 'react'
import '../style/Cargar.css'

export const Cargar = () => {
    return (
        <div id='cargar' className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}
