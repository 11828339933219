import React from 'react'
import { Link } from 'react-router-dom'
import '../style/ButtonLogout.css'
import Cookies from 'universal-cookie'

export const ButtonLogout = () => {
    const cookies=new Cookies();

    const handleClick=()=>{

        cookies.set('IdU','',{path:'/'});
        cookies.set('nombreU','',{path:'/'});
        cookies.set('tipoU','',{path:'/'});
    };

    return (
        <div className='row mt-2'>
            <div id='user-login' >
                <div className='fs-4'>
                    <i className="fa-solid fa-user"></i>&nbsp;
                    {cookies.get('nombreU')}
                </div>
            </div>
            <div id='button-logout' className='btn'>
                <Link to='/login' onClick={handleClick}>
                    <div className='fs-4'>
                        <i className="fa-sharp fa-solid fa-circle-xmark"></i>&nbsp;
                        Logout
                    </div>
                </Link>
            </div>
        </div>
    )
}
