import React from 'react'
import { Link } from 'react-router-dom'

export const HomeA = () => {
    return (

        <>
            <div className='col-3'>
                <div className='lista-nav'>
                    <ul className="list-group">
                        <li className="list-group-item list-group-item-success">
                            <Link to='/formularios/plantilla'>
                                <div className='fs-5'>
                                    <i className="fa-solid fa-desktop me-2"></i>
                                    Monitoreo
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <br />
            <div className='col-3'>
                <div className='lista-nav'>
                    <ul className='list-group'>
                        <li className="list-group-item list-group-item-success">
                            <Link to='/buscador'>
                                <div className='fs-5'>
                                    <i className="fa-solid fa-binoculars me-2"></i>
                                    Buscador
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <br />
            <div className='col-3'>
                <div className='lista-nav'>
                    <ul className='list-group'>
                        <li className="list-group-item list-group-item-success">
                            <Link to='/gestionUsuarios'>
                                <div className='fs-5'>
                                    <i className="fa-solid fa-list-check me-2"></i>
                                    Gestión Usuarios
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <br />
            <div className='col-3'>
                <div className='lista-nav'>
                    <ul className='list-group'>
                        <li className="list-group-item list-group-item-success">
                            <Link to='/perfilUsuario'>
                                <div className='fs-5'>
                                    <i className="fa-solid fa-user me-2"></i>
                                    Perfil Usuario
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>

    )
}
