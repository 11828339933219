import React, { useEffect, useState } from 'react'
import axios from "axios";

export const useFormLogin = (initialForm,validateForm,setPost) => {

    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    //var baseURL = "https://localhost:8080/usuarios/ingresar";
    var baseURL ="https://monitortuga.monitoreomarinocosterord.com:8442/usuarios/ingresar"

    const handleChange = (e) => {

        const { name, value } = e.target;
        
        setForm({
            ...form,
            [name]: value
        });

        //console.log(form)
    }

    const handleBlur=(e)=>{

        handleChange(e);
        setErrors(validateForm(form));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //console.log(errors)
        if(Object.entries(errors).length==0 && form.user!='' && form.pw!=''){

        //console.log('Datos a enviar')
        //console.log(form);
        
        //console.log('Post');

        axios
            .post(baseURL, form)
            .then((response) => {

                //console.log(response);
                setPost(response);
                setForm(initialForm);

            }).catch(error => { 
                
                //console.log(error);
                setPost(error.response);
                setForm(initialForm);

            });
        }
    }

    return{
       form,
       errors,
       loading,
       response,
       handleChange,
       handleBlur,
       handleSubmit 
    };
}
