import React, { useEffect, useState } from 'react'
import '../style/InputTypeTextO.css'

export const InputTypeTextO = ({ dataType, data, setData, editar, dataObserver}) => {

    let nameType = dataType.toLowerCase();

    if (editar === true) {

        if (dataObserver != null && dataObserver != undefined && Object.entries(dataObserver).length != 0) {

            Object.keys(dataObserver).map((elem, index) => {

                if (elem == nameType)
                    {
                        if(document.getElementById(nameType)!=null)
                            document.getElementById(nameType).value = Object.values(dataObserver)[index];
                    }

            });
        }
    }

    const handleInput = e => {

        var propiedad = '';
        var valor = '';
    
        propiedad = e.target.name.toLowerCase();
        valor = e.target.value;

        const nodoJSON = {

            ...data,
            [propiedad]: valor,

        }

        setData(nodoJSON);
        //console.log(data);
    }

    return (
        <>
            <div className="input-group input-group-lg">
                {
                    nameType === 'edad' ?

                        <>
                            <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                            <input type="number" className="form-control" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                onChange={handleInput} disabled={editar} autoComplete="off" />
                        </>

                        :

                        nameType === 'email' ?
                            <>
                                <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                <input type="text" className="form-control" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                    onChange={handleInput} disabled={editar} autoComplete="off" />
                            </>
                            :
                            nameType === 'profesion' ?
                            <>
                                <span className="input-group-text" id="inputGroup-sizing-lg">Profesión</span>
                                <input type="text" className="form-control" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                    onChange={handleInput} disabled={editar} autoComplete="off" />
                            </>
                            :
                            <>
                                {
                                    nameType.includes('_') ?
                                        <></>
                                        :
                                        <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                }
                                <input type="text" className="form-control" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                    onChange={handleInput} disabled={editar} autoComplete="off" />
                            </>
                }
            </div>
            <br />
        </>
    )
}