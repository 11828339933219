import React from 'react'
import '../../Home/style/Home.css'
import tortuga from '../../Imagenes/tortuga.png'
import atabey from '../../Imagenes/atabey.jpg'
import { ButtonLogin } from '../../Login/components/ButtonLogin'
import Cookies from 'universal-cookie'
import { HomeA } from './HomeA'
import { HomeU } from './HomeU'
import { ButtonLogout } from '../../Login/components/ButtonLogout'

export const Home = () => {

    const cookies =new Cookies();

    return (
        <div className='container p-2'>
            <div className='row mt-2 mb-4'>
                <div className='col-12'>
                    <h1>Bienvenidos al centro de monitorizacion de tortugas marinas</h1>
                </div>
                {
                    cookies.get('tipoU') == 'admin' || cookies.get('tipoU') == 'user' ?
                        <ButtonLogout />
                        :
                        <ButtonLogin />
                }
            </div>
            <img id='iatabey' className='rounded mb-4' src={atabey}/>
            <div id='imagen-tortuga'>
                <img className='mt-2 mb-2' src={tortuga} />
            </div>
            <div className='row mt-4'>
                {
                    cookies.get('tipoU') == 'admin' ?
                    <HomeA/>
                    :
                    cookies.get('tipoU') == 'user' ?
                    <HomeU/>
                    :
                    <>
                    </>
                }
            </div>
            <br/>
            <div className="col-12 alert alert-success mt-4" role="alert" >
                <a href="https://monitoreomarinocosterord.com/index.php/tortugas-marinas/"
                    target="_blank" className="link-success">
                        Enterate más sobre el Monitoreo de Tortugas Marinas
                </a>
            </div>
        </div>
    )
}
