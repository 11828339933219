export const MensajeError = (error_message, tipo) => {

    var mensaje = '';
    var campos = '';

    //console.log(error_message);
    if (error_message == 'nombre_playa,') {
        mensaje = 'Error, se debe ingresar el nombre de una playa';
    } else {

        if (typeof error_message == 'string' && error_message.includes('Error')) {

            mensaje = error_message;

        } else {
            if (error_message.code.includes('ERR_NETWORK')) {

                mensaje = "Error en los servicios";

            } else if (error_message.code.includes('ERR_BAD_RESPONSE')) {

                if (error_message.response.data.message.includes('Validation failed')) {

                    error_message.response.data.message.split(',').map((elem) => {

                        if (elem.includes('propertyPath=') && !elem.includes('mndha') && !elem.includes('mddn') &&
                            !elem.includes('mnee') && !elem.includes('mam'))
                            campos += elem.substring(14, (elem.length)) + ' , ';
                    });

                    mensaje = 'Error ingresar campos faltantes: ' + campos;

                } else if (error_message.response.data.message.includes('could not execute statement')) {

                    if (tipo == 1) mensaje = 'Error posibles campos repetidos en otro registro: nombre, email';
                    else if (tipo == 2) mensaje = 'Error posibles campos repetidos en otro registro: nombre_playa';
                    else if (tipo == 3) mensaje = 'Error posibles campos repetidos en otro registro: hora';

                } else if (error_message.response.data.message.includes('Could not commit JPA transaction')) {

                    if (tipo == 1) mensaje = 'Error al actualizar, datos sin ingresar';
                    else if (tipo == 2) mensaje = 'Error al actualizar en formato fecha o datos sin ingresar';
                    else if (tipo == 3) mensaje = 'Error al actualizar en formato hora o datos sin ingresar';
                }

            } else if (error_message.code.includes('ERR_BAD_REQUEST')) {

                if (error_message.response.data.message.includes('Cannot deserialize value of type `java.time.LocalDate`')) {
                    if (tipo == 3) mensaje = 'Error en formato de campo fecha_ec_ex';

                } else if (error_message.response.data.message.includes('Cannot deserialize value of type `java.time.LocalTime`')) {

                    if (tipo == 1 || tipo == 4)
                        mensaje = 'Error en el formato del campo hora';
                }

            } else {
                mensaje = "Error";
            }
        }
    }

    return mensaje;
}