import React from 'react'
import '../style/ObserverTable.css'

export const ObserverTable = ({ obs }) => {

    return (
        <>
            {
                Object.entries(obs).length != 0 ?
                    <table id='observer-table' className="table">
                        <tbody>
                            <tr className='tr-borde'>
                                <td >Nombre</td>
                                <td className='td-claro'>{obs.nombre}</td>
                            </tr>
                            <tr className='tr-borde'>
                                <td>Edad</td>
                                <td className='td-claro'>{obs.edad}</td>
                            </tr >
                            <tr className='tr-borde'>
                                <td>País</td>
                                <td className='td-claro'>{obs.pais}</td>
                            </tr>
                            <tr className='tr-borde'>
                                <td >Email</td>
                                <td className='td-claro'>{obs.email}</td>
                            </tr>
                            <tr className='tr-borde'>
                                <td >Institución / Organización</td>
                                <td className='td-claro'>{obs.institucion_organizacion}</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <>
                        <h3>Sin Datos</h3>
                    </>
            }
            <br />
        </>
    )
}
