export default [
    'Número del nido',	
    'Fecha de eclosión o exhumación',	
    'Especie',	
    'Número de crías vivas o Neonatos vivos',
    'Número de huevos que han eclosionado(cascarones)',
    'Número de crías muertas o Neonatos muertos',
    'Número de huevos sin desarrollo aparente (infértiles)',	
    'Huevos Depredados',	
    'Total de Huevos no eclosionados',	
    //'Huevos Infertiles',	
    'Número Total de Huevos',
    'Crías en superficie (corral)',	
    'Riesgos en nido o Pérdida del nido: inundación, saqueo, depredación, erosión, mareas extraodinarias, recale de sargazo'    
]