export const VerificarInfo3 = (infoJSON) => {

    let campo_v = '';
    let caracteres = '';
    var data={};

    //console.log("verificando")
    //console.log(Object.values(infoJSON));

    Object.values(infoJSON).forEach((elem, index) => {

        if (Object.keys(infoJSON)[index] != 'tipo_moni' && Object.keys(infoJSON)[index] != 'id' ) {

            if (Object.keys(infoJSON)[index].toLowerCase() != 'num_nido' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'num_huevos' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'temp_nido' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'prof_nido' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'vistransver_valor' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'tempa_valor' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'talla_estimada' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'num_marcas_tags'&&
                Object.keys(infoJSON)[index].toLowerCase() != 'latitud'&&
                Object.keys(infoJSON)[index].toLowerCase() != 'longitud' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'morfometria_acc' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'morfometria_lscc' &&
                Object.keys(infoJSON)[index].toLowerCase() != 'ancho_cabeza' &&
                elem!='') {

                caracteres = elem.split('')
                    .filter((elem) => elem !== '.' && elem !== ',' && elem !== ';' &&
                        elem !== '=' && elem !== '/' && elem !== String.fromCharCode(47) &&
                        elem !== '*' && elem !== '%' && elem !== String.fromCharCode(39))
                //47 es '\' y 39 es '

                data[Object.keys(infoJSON)[index]] = caracteres.join('');

            }else{
                data[Object.keys(infoJSON)[index]]=Object.values(infoJSON)[index];
            }
        }

    });

        return data;
}