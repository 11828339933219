import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MensajeBusqueda } from './MensajeBusqueda';
import { Sanitizar } from '../config/SanitizarBusqueda';

export const FormBuscador = ({ obs, setObs, setSObs }) => {

    const [buscar, setBuscar] = useState({});
    const [tipo, setTipo] = useState('');
    const [respuesta, setRespuesta] = useState(0);
    const [get, setGet] = useState({});

    //var baseURL = 'http://localhost:8080/'
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/";

    useEffect(() => {

        if (get != null && Object.entries(get).length != 0) {

            if (get.config.url.includes('observadores')) {

                var ArrayObs = [];

                if (get.data.length != 0) {
                    get.data.map(elem => {

                        ArrayObs.push(elem);

                    });

                    setObs(ArrayObs);

                } else {

                    setRespuesta(2);
                    setObs([]);
                }

            } else if (get.config.url.includes('nombre') ||
                get.config.url.includes('fecha')) {

                var ArrayObs = [];

                if (get.data.length != 0) {
                    axios.get(`${baseURL}observadores`)
                        .then((response) => {

                            get.data.map(elem => {

                                response.data.map(elem2 => {

                                    if (elem === elem2.id)
                                        ArrayObs.push(elem2);

                                });
                            });

                            setObs(ArrayObs);
                        });
                } else {
                    
                    setRespuesta(2);
                    setObs([]);
                }
            }
        }

    }, [get])


    const handleInput = (e) => {

        let aux = '';

        const busca = {

            ...buscar,
            [e.target.name]: e.target.value,

        }

        //console.log(busca);

        if (busca.tipo === 'nombre_obs') aux = 'Nombre del observador';
        else if (busca.tipo === 'nombre_sObs') aux = 'Nombre del sitio';
        else if (busca.tipo === 'fecha_sObs') aux = 'Fecha de registro';

        setTipo(aux);
        setBuscar(busca);

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let peticion = '';
        let busqueda = '';

        if (Object.entries(buscar).length != 0 && buscar.tipo != null &&
            buscar.palabra_clave != null && buscar.palabra_clave != '') {

            busqueda = Sanitizar(buscar.palabra_clave);

            if (buscar.tipo === 'nombre_obs')
                peticion = `${baseURL}observadores/${busqueda}`;

            else if (buscar.tipo === 'nombre_sObs')
                peticion = `${baseURL}observadorId/sitioObservado/nombre/${busqueda}`;

            else if (buscar.tipo === 'fecha_sObs')
                peticion = `${baseURL}observadorId/sitioObservado/fecha/${busqueda}`;

            axios.get(peticion)
                .then((response) => {

                    //console.log('GET lista de registros')
                    setGet(response);
                    //console.log(response);

                    setRespuesta(1);
                })
                .catch((error) => setRespuesta(2));

        } else {

            setRespuesta(3);
        }
    }

    return (
        <div className='buscador-div-standar'>
            <div id='form-buscador'>
                <form className='form control' onSubmit={handleSubmit} >
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>
                                    <input type='radio' id='nobreObs'
                                        className="form-check-input"
                                        name='tipo' value='nombre_obs'
                                        onClick={handleInput}
                                    />
                                    <label className="form-check-label" forhtml='nombre_obs' >Observador</label>
                                </td>
                                <td>
                                    <input type='radio' id='nobreSObs'
                                        className="form-check-input"
                                        name='tipo' value='nombre_sObs'
                                        onClick={handleInput} />
                                    <label className="form-check-label" forhtml='nombre_sObs' >Nombre sitio</label>
                                </td>
                                <td>
                                    <input type='radio' id='fechaSObs'
                                        className="form-check-input"
                                        name='tipo' value='fecha_sObs'
                                        onClick={handleInput} />
                                    <label className="form-check-label" forhtml='fechaSObs' >Fecha </label>
                                </td>
                                <td>
                                    <input type='text' className="form-control"
                                        placeholder={tipo}
                                        name='palabra_clave'
                                        id='palabraClave'
                                        onChange={handleInput}
                                    />
                                </td>
                                <td>
                                    <button id='bsubmit' type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}>
                                        Buscar
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <MensajeBusqueda mensajeR={respuesta} />
            </div>
        </div>
    )
}
