import React from 'react'

export const MensajeBusqueda = ({mensajeR}) => {
  return (
    <div>
          {
              mensajeR === 1 ?
                  <div className="alert alert-success" role="alert">
                      Búsqueda exitosa
                  </div>
                  :
                  <>
                  </>
          }
          {
              mensajeR === 2 ?
                  <div className="alert alert-warning" role="alert">
                      No se han encontrado registros
                  </div>
                  :
                  <>
                  </>
          }
          {
              mensajeR === 3 ?
                  <div className="alert alert-danger" role="alert">
                      Ingresar el tipo de búsqueda y una palabra clave para buscar
                  </div>
                  :
                  <>
                  </>
          }
    </div>
  )
}
