import React, { useEffect, useState } from 'react'
import { FormTable } from '../../components/FormTable'
import { ShowTable } from '../../components/ShowTable'
import tableFields3 from '../config/TableFields3';
import headersTable3 from '../config/TableHeaders3';
import '../../style/ContentDeployer.css'

export const ContentDeployerC3 = ({sObs,moniTablas, setEnviado,numtabla}) => {

    const [visible,setVisible] =useState(false);
    const [accion, setAccion] = useState('');
    const [registros,setRegistros]=useState([]);
    const [registroE,setRegistroE]=useState({});
    const [mensajeR,setMensajeR]=useState(0);
    const [mensajeA,setMensajeA]=useState(0);
    const [mensajeError, setMensajeError] = useState('');
    const [data, setData] = useState({});
    const [cargar,setCargar]=useState(false);

    if (cargar == false) {
        if (moniTablas[2] != null && moniTablas[2] != undefined && moniTablas[2].length != 0) {

            setRegistros(moniTablas[2]);
            setCargar(true);
        }
    }

    useEffect(() => {

        //console.log("U Data")
        //console.log(data);

    }, [data]);

    useEffect(()=>{

        if(visible){
            document.getElementsByClassName("form-control").namedItem("num_nido").focus(); 

            /*
                num_nido:0,
                especie:'',
                num_crias_vivas:0,
                num_huevos_eclo:0,
                num_crias_muertas:0,
                num_huevos_sin_des_inf:0,
                huevos_dep:0,
                total_huevos_no_eclo:0,
                num_total_huevos:0,
                crias_sup_corral:0,
                riesgo_perdida:'',
            */
        }
    },[visible])

    return (
            <div id='content'>
                <br/>
                <ShowTable headers={headersTable3} 
                    setAccion={setAccion} 
                    setVisible={setVisible}
                    setRegistroE={setRegistroE}
                    registros={registros} 
                    sObs={sObs} 
                    setRegistros={setRegistros}
                    mensajeR={mensajeR}
                    setMensajeR={setMensajeR}
                    mensajeA={mensajeA}
                    setMensajeA={setMensajeA}
                    mensajeError={mensajeError}
                    tabla={'mnee'}
                    setEnviado={setEnviado}
                    data={data}
                    setData={setData}                  
                    />
                {
                    visible? <FormTable titulo={"Características monitoreadas"} 
                                        campos={tableFields3} 
                                        accion={accion}               
                                        sObs={sObs} 
                                        setRegistros={setRegistros}
                                        setVisible={setVisible}
                                        visible={visible}
                                        registroE={registroE}
                                        mensajeR={mensajeR}
                                        setMensajeR={setMensajeR}
                                        setMensajeA={setMensajeA}
                                        mensajeError={mensajeError}
                                        setMensajeError={setMensajeError}  
                                        tabla={'mnee'}
                                        tipotabla={3}
                                        setEnviado={setEnviado}
                                        data={data}
                                        setData={setData}   
                                       />
                        :
                        <></>
                }
                <br/>
            </div>
    )
}
