import React from 'react'

export const MensajeRespuesta = ({mensajeR,mensajeError}) => {
    return (
        <div className='mt-2'>
            {
                mensajeR === 1 ?
                    <div className="alert alert-success" role="alert">
                        El registo se ha creado exitosamente.
                    </div>
                    :
                    <>
                    </>
            }
            {
                mensajeR === 2 ?
                    <div className="alert alert-warning" role="alert">
                        El registo se ha actualizado.
                    </div>
                    :
                    <>
                    </>
            }
            {
                mensajeR === 3 ?
                    <div className="alert alert-warning" role="alert">
                        El registo se ha eliminado.
                    </div>
                    :
                    <>
                    </>
            }
            {
                mensajeR === 4 ?
                    <div className="alert alert-danger" role="alert">
                        {mensajeError}
                    </div>
                    :
                    <>
                    </>
            }
            {
                mensajeR === 5 ?
                    <div className="alert alert-danger" role="alert">
                        Por favor ingresar datos
                    </div>
                    :
                    <>
                    </>
            }
        </div>

    )
}
