import React, { useEffect, useState } from 'react'
import axios from "axios";
import '../style/FormTable.css'

import { VerificarInfo3 } from '../../Funciones/VerificarInfo3';
import { MensajeError } from '../../Funciones/MensajeError';

import { Label1 } from './Label1';
import { Label2 } from './Label2';

import { Ayuda } from '../../Sistema/components/Ayuda';
import { MensajeRespuesta } from '../../Sistema/components/MensajeRespuesta';
import { CapturaVaciosF } from '../../Funciones/CapturaVaciosF';
import { InputTypeTextT } from './InputTypeTextT';
import { TextAreaT } from './TextAreaT';
import { CrearVacios } from '../funciones/CrearVacios';

export const FormTable = ({ titulo, campos, accion, sObs, 
                            setRegistros, setVisible, registroE, 
                            mensajeR,setMensajeR, setMensajeA, 
                            mensajeError, setMensajeError, tabla,
                            tipotabla,setEnviado,data,setData}) => {

    const [post, setPost] = useState(null);
    const [put, setPut] = useState(null);

    //var baseURL = "http://localhost:8080/sitioObservado";
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/sitioObservado";

    useEffect(() => {

        if (registroE != null && registroE != undefined && Object.entries(registroE).length != 0) {

            //console.log("RegistroE")
            //console.log(registroE)

            if (tipotabla == 1) {
                
                if (document.getElementById('radiotgps1').value == registroE.tortuga_gps) {

                    document.getElementById('radiotgps1').setAttribute("checked", true);

                } else if (document.getElementById('radiotgps2').value == registroE.tortuga_gps) {

                    document.getElementById('radiotgps2').setAttribute("checked", true);
                }
            }
        }

    }, [])

    useEffect(() => {

        //console.log("U Post"); 

        if (post != null && Object.entries(post).length != 0) {

            setData(post);
            
                axios.get(`${baseURL}/${sObs.id}/${tabla}`)
                    .then((response) => {

                        //console.log('Post - lista de registros');
                        //console.log(response.data);
                        
                        setRegistros(response.data);

                        setVisible(false);
                        setMensajeA(1);
                        setEnviado(tipotabla);
                        //document.getElementById('imgt1').focus();

                    })
                    .catch((error) => {
                        //console.log(error);
                        setMensajeA(2);
                        setMensajeError('Error en actualizar los datos en la tabla');
                    });
        }

    }, [post]);

    useEffect(() => {

        //console.log("U Put");
        
        if (put != null && Object.entries(put).length != 0) {

                setData(put);

                axios.get(`${baseURL}/${sObs.id}/${tabla}`)
                    .then((response) => {

                        //console.log('Put - lista de registros')
                        //console.log(response.data);
                        
                        setRegistros(response.data);
                        setVisible(false);
                        setMensajeA(1);
                        setEnviado(tipotabla);

                    })
                    .catch((error) => {
                        //console.log(error);
                        setMensajeA(2);
                        setMensajeError('Error en actualizar los datos en la tabla');
                    });
            
        }

    }, [put]);

    const handleSubmit = (e) => {

        e.preventDefault();
        //let campos_vacios = '';

        if (data !== null && data !== undefined && Object.entries(data).length !== 0) {

            var infoJSON = VerificarInfo3(data);

            //campos_vacios = CapturaVaciosF(infoJSON);
            //if (campos_vacios == '') {

            infoJSON=CrearVacios(infoJSON,tipotabla);

                //console.log('Datos para enviar')
                //console.log(infoJSON);

                if (accion === 'Enviar') {

                    //console.log('Post')
                    delete infoJSON.id;

                    axios
                        .post(`${baseURL}/${sObs.id}/${tabla}`, infoJSON)
                        .then((response) => {
                            
                            //console.log(response);
                            setPost(response.data);
                            setMensajeR(1);

                        }).catch(error => {

                            //console.log(error);
                            setMensajeR(4);
                            setMensajeA(2);
                            setMensajeError(MensajeError(error, tipotabla));
                        });

                } else if (accion === 'Actualizar') {

                    //console.log('Put')

                    axios
                        .put(`${baseURL}/${sObs.id}/${tabla}/${registroE.id}`, infoJSON)
                        .then((response) => {

                            setPut(response.data);
                            setMensajeR(2);

                        }).catch(error => {

                            //console.log(error);
                            setMensajeError(MensajeError(error, tipotabla));
                            setMensajeR(4);
                            setMensajeA(2);
                        });
                }
            /*} else {

                setMensajeError(MensajeError(campos_vacios, tipotabla));
                setMensajeR(4);
                return;
            }*/

        } else {

            setMensajeR(5);
            return;
        }
    }

    const handleCancel=()=>{

        setVisible(false);
    }

    const handleInput = e => {

        //console.log(data);

        var propiedad = '';
        var valor = '';

        propiedad = e.target.name.toLowerCase();
        valor=e.target.value;

        const nodoJSON = {

            ...data,
            [propiedad]: valor,

        }

        setData(nodoJSON);
        //console.log(data);
    }

    return (
        <>
            <div className='div-standar2'>
                <h2>{titulo}</h2>   
                <div className='row mx-auto'>
                        <div className='col-6 mx-auto'>
                            <button id='bsubmit' type="submit"
                                className="btn btn-primary me-1"
                                onClick={handleSubmit} >
                                {accion}
                            </button>
                            <button id='button' type="button"
                                className="btn btn-secondary"
                                onClick={handleCancel} >
                                Cancelar
                            </button>
                        </div>
                    </div>
                <br />
                <br/>
                {
                    registroE != null &&  registroE != undefined  && Object.entries(registroE).length!=0 ?

                        <div className="alert alert-warning" role="alert">
                            Actualizar todos los datos !
                        </div>
                        :
                        <>
                        </>
                }
                <form onSubmit={handleSubmit}>

                        {
                            campos.map((elem, index) => {

                                if (elem.tipo === 'texto') {

                                    return <InputTypeTextT key={index}
                                            dataType={elem.campo}
                                            data={data}
                                            setData={setData}
                                            registroE={registroE}
                                    />
                                }

                                else if (elem.tipo === 'espacio') return <><hr /><br /></>

                                else if (elem.tipo === 'titulo1') return <Label1 key={index} title={elem.campo} />

                                else if (elem.tipo === 'titulo2') return <Label2 key={index} title={elem.campo} />

                                else if (elem.tipo === 'ayuda') return <Ayuda key={index} 
                                                                              titulo={elem.titulo} 
                                                                              mensaje={elem.mensaje} />

                                else if (elem.tipo === 'textoArea') return <TextAreaT key={index} 
                                                                                 dataType={elem.campo}
                                                                                 data={data}
                                                                                 setData={setData}
                                                                                 registroE={registroE} />
                            })
                        }
                    {tipotabla == 1 ?
                        <>
                            <div className="row mx-auto mb-3">
                                <div className='col-12 form-label fs-4' forhtml='tortuga_gps'>
                                    Tortuga tiene GPS
                                </div>
                                <div className='col'>
                                    <input className="form-check-input me-1" type='radio' id='radiotgps1'
                                        name='tortuga_gps' value='SI'
                                        onClick={handleInput}
                                    />
                                    <label className="form-label" forhtml="radiotgps1" >SI</label>
                                </div>
                                <div className='col mb-3'>
                                    <input className="form-check-input me-1" type='radio' id='radiotgps2'
                                        name='tortuga_gps' value='NO'
                                        onClick={handleInput}
                                    />
                                    <label className="form-label" forhtml="radiotgps2" >NO</label>
                                </div>
                                <hr />
                            </div>
                        </>
                        :
                        <>
                        </>
                    }
                    <div className='row mx-auto'>
                        <div className='col-6 mx-auto'>
                            <button id='bsubmit' type="submit"
                                className="btn btn-primary me-1"
                                onClick={handleSubmit} >
                                {accion}
                            </button>
                            <button id='button' type="button"
                                className="btn btn-secondary"
                                onClick={handleCancel} >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </form>
                <div className='div-mensajes'>
                    {
                        mensajeR === 4 || mensajeR === 5 ?
                            < MensajeRespuesta mensajeR={mensajeR} mensajeError={mensajeError} />
                            :
                            <></>
                    }
                </div>
            </div>
        </>
    )
}
