export default [
    'Hora',
    'Actividad',
    'Coordenada del Nido Latitud',
    'Coordenada del Nido Longitud',	
    'Número de nido',
    'Especie',
    'MorfometríaLSCC Largo curvo estándar del carapacho',
    'Morfometría ACC Ancho curvo del carapacho',	
    'Estado físico de la tortuga o daño presente',	 
    'Ancho de cabeza (AC)',	
    //'Zona balizas: Ubicación del nido respecto a las balizas',
    'Número o conteo de huevos',
    'Nido en perfil: Ubicación del nido en el perfil de playa',	
    'Temperatura del nido',
    'Profundidad del nido',	
    'Marca o Tags',
    'Tortuga tiene GPS',	
    'Observaciones',
]