import React, { useEffect, useState } from 'react'
import { FormBuscador } from './FormBuscador'
import '../style/Buscador.css'
import { ButtonHome } from '../../Home/components/ButtonHome';
import { ContenedorSObsTablas } from './ContenedorSObsTablas'

export const Buscador = () => {

  const [obs, setObs] = useState([]);
  const [sObs, setSObs] = useState({});

  return (
    <div className='container p-4'>
      <br/>
      <h2>Buscador</h2>
      <br />
      <ButtonHome />
      <br />
      <FormBuscador obs={obs} setObs={setObs} setSObs={setSObs} />
      <div className="buscador-div-standar">
        {
          obs != null && obs.length != 0 ?
            obs.map((elem,index )=> <ContenedorSObsTablas obs={elem} key={index+'co'} />)
            :
            <h3>Sin Datos</h3>
        }
        <br />
      </div>
      <div className="alert-info" role="alert"></div>
      <br />
      <br />
      <div className="alert alert-success" role="alert" target="_blank">
                <a href="https://monitoreomarinocosterord.com/index.php/tortugas-marinas/"
                    target="_blank" className="link-success">
                      Enterate más sobre el Monitoreo de Tortugas Marinas
                </a>
            </div>
    </div>
  )
}
