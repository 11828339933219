import React, { useState } from 'react'
import { SitioObservadoTable } from './SitioObservadoTable'
import { MonNocDetHemAni } from './MonNocDetHemAni';
import { MonDiurDetNid } from './MonDiurDetNid';
import { MonNidEEclo } from './MonNidEEclo';
import { MonAreaMarina } from './MonAreaMarina';
import { ObserverTable } from './ObserverTable';

export const ContenedorSObsTablas = ({ obs }) => {

  const [mostrarC, setMostrarC] = useState(false);

  const handleClic = () => setMostrarC(!mostrarC);

  return (
    <div>
      <br />
      <div className="d-grid gap-2 col-10 mx-auto">
        <button type='button' className='btn btn-primary' onClick={handleClic}>{obs.nombre}</button>
      </div>
      <br />
      {
        mostrarC ?
          <>
            <h2>Observador</h2>
            <ObserverTable obs={obs} />
            <h2>Sitios Observados <span>{obs.sob.length}</span></h2>
            <br/>
            {
              obs.sob.map((elem,index) => {

                return (

                  <>
                    <div className="alert alert-info" role="alert">
                      <h4 className="alert-heading">{elem.nombre_playa}</h4>
                    </div>
                    <h3>Datos Sitio Observado</h3>
                    <SitioObservadoTable key={elem.id+index+'sobt'} sObs={elem} />
                    <h3>Monitoreo Nocturo para la Detección de Hembras Anidadoras</h3>
                    <MonNocDetHemAni key={elem.mndha.id+index+'mndhat'} tabla1={elem.mndha} />
                    <h3>Monitoreo Diurno para la Detección de Nidos</h3>
                    <MonDiurDetNid key={elem.mddn.id+index+'mddnt'} tabla2={elem.mddn} />
                    <h3>Monitoreo de Nidos en Eclosión o Eclosionados</h3>
                    <MonNidEEclo key={elem.mnee.id+index+'mneet'} tabla3={elem.mnee} />
                    <h3>Monitoreo en Área Marina</h3>
                    <MonAreaMarina key={elem.mam.id+index+'mamt'} tabla4={elem.mam} />
                    <div className="alert-info" role="alert"></div>
                  </>

                );
              })
            }
          </>
          :
          <>
          </>
      }
      <br />
    </div>
  )
}
