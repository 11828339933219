import React from 'react'
import '../style/SitioObservadoTable.css'
import titulos from '../config/SObsHeaderFields'

export const SitioObservadoTable = ({ sObs }) => {
    return (
        <>
            {
                Object.entries(sObs).length != 0 ?
                        <div className='show-scroll'>
                            <table id='sObs-table' className="table table-bordered">
                                <thead>
                                    <tr>
                                        {
                                            titulos.map((elem,index) => {

                                                return (
                                                    elem.titles2 != null || elem.titles2 != undefined ?
                                                        <td key={index+'h1sob'} colSpan={elem.titles2.split(',').length}>{elem.title1}</td>
                                                        :
                                                        <td></td>
                                                );
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            titulos.map((elem,index) => {

                                                return (
                                                    elem.titles2 != null || elem.titles2 != undefined ?
                                                        elem.titles2.split(',').map(elem2 => <td>{elem2}</td>)
                                                        :
                                                        <td key={index+'h2sob'}>{elem.title1}</td>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                            <tbody>
                                <tr>
                                    <td>{sObs.fecha}</td>
                                    <td >{sObs.nombre_playa}</td>
                                    <td >{sObs.cgpsinicio_lat}</td>
                                    <td>{sObs.cgpsinicio_long}</td>
                                    <td>{sObs.cgpsfin_lat}</td>
                                    <td>{sObs.cgpsfin_long}</td>
                                    <td>{sObs.pais}</td>
                                    <td>{sObs.estado_provincia}</td>
                                    {/*<td>{sObs.poblacion_cercana}</td>*/}
                                    <td>{sObs.anp}</td>
                                    <td>{sObs.nombre_anp}</td>
                                    {
                                        sObs.prof_valor != null && sObs.prof_valor != 0 ?
                                            <td>{sObs.prof_valor}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.prof_unidad != null ?
                                            <td>{sObs.prof_unidad}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.prof_medida_con != null ?
                                            <td>{sObs.prof_medida_con}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.vistransver_valor != null && sObs.vistransver_valor != 0 ?
                                            <td>{sObs.vistransver_valor}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.vistransver_unidad != null ?
                                            <td>{sObs.vistransver_unidad}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.vistransver_medida_con != null ?
                                            <td>{sObs.vistransver_medida_con}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tempa_valor != null && sObs.tempa_valor != 0 ?
                                            <td>{sObs.tempa_valor}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tempa_unidad != null ?
                                            <td>{sObs.tempa_unidad}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tempa_medida_con != null ?
                                            <td>{sObs.tempa_medida_con}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tempa_medida_en != null ?
                                            <td>{sObs.tempa_medida_en}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tec_monitoreo != null ?
                                            <td>{sObs.tec_monitoreo}</td>
                                            :
                                            <td>NA</td>
                                    }
                                    {
                                        sObs.tipo_fondo != null ?
                                            <td>{sObs.tipo_fondo}</td>
                                            :
                                            <td>NA</td>
                                    }
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        :
                        <p>Sin Datos</p>
            }
            <br />
            
        </>
    )
}
