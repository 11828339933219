export default [
    {
      title1: 'Coordenadas GPS',
      titles2:'Latitud,Longitud'
    },
    {
      title1: 'Especie'
    },
    {
      title1:'Número del Nido'
    },
    {
      title1: "Nido en perfil: Ubicación del nido en el perfil de playa"
    },
    {
      title1: "Condiciónn del nido"
    },
    {
      title1: "Número de huevos por nido"
    },
  ]
