import './App.css';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './Home/components/Home';
import { Plantilla } from './Plantilla/components/Plantilla';
import { Buscador } from './Buscador/components/Buscador';
import { Login } from './Login/components/Login';
import { GestionUsuarios } from './GestionarUsuario/pages/GestionUsuarios';
import { ProfileUsuario } from './GestionarUsuario/pages/ProfileUsuario';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/login' element={<Login/>}/>
          <Route path="/formularios/plantilla" element={ <Plantilla /> }/>
          <Route path="/buscador" element={ <Buscador /> }/>
          <Route path="/gestionUsuarios" element={ <GestionUsuarios /> }/>
          <Route path="/perfilUsuario" element={ <ProfileUsuario /> }/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
