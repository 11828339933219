import React, { useState,useEffect } from 'react'
import axios from "axios";

import '../../Locacion/style/FormLocation.css'
import { MensajeRespuesta } from '../../Sistema/components/MensajeRespuesta';
import { MensajeError2 } from '../../Funciones/MensajeError2';
import VerificarInfo2 from '../../Funciones/VerificarInfo2';
import { CapturaVaciosE } from '../../Funciones/CapturaVaciosE';
import { MensajeError } from '../../Funciones/MensajeError';

export const FormLocation = ({setDisableBT,setSObs,observer,busquedaSO, setBusquedaSO,setDisableBL }) => {

    const [data, setData] = useState({

        anp: "",
        cgpsfin_lat: 0,
        cgpsfin_long: 0,
        cgpsinicio_lat: 0,
        cgpsinicio_long:0, 
        estado_provincia:"",
        nombre_anp: "",
        //nombre_playa: "",
        pais: "",
        prof_medida_con: "",
        prof_unidad: "",
        prof_valor:0, 
        tec_monitoreo: "",
        tempa_medida_con:"", 
        tempa_medida_en: "",
        tempa_unidad:"",
        tempa_valor: 0,
        tipo_fondo: "",
        vistransver_medida_con:"",
        vistransver_unidad:"",
        vistransver_valor:0 
    });

    const [editar,setEditar]=useState(false);
    const [bEnviar, setBEnviar] = useState(true);
    const [post, setPost] = useState(null);
    const [actualizar, setActualizar] = useState(false);
    const [put, setPut] = useState(null);
    const [error, setError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeR, setMensajeR] = useState(0);
    const [cargar, setCargar] = useState(false);
    const [cargar2, setCargar2] = useState(false);

    var infoJSON;
    //var baseURL = "http://localhost:8080";
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442";

    if (cargar == false) {
        if (busquedaSO != null && busquedaSO != undefined && Object.entries(busquedaSO).length != 0) {

            //console.log('FormLocation')
            //console.log(busquedaSO);
            setData(busquedaSO);
            setEditar(true);
            setBEnviar(false);
            setCargar(true);
            setDisableBL(true);
        }
   }

    const handleInput = e => {

        var propiedad = '';
        var valor = '';

        propiedad=e.target.name;
        valor=e.target.value;

        infoJSON = {

            ...data,
            [propiedad]: valor,

        }

        setData(infoJSON);
        //console.log(data);
    };

    useEffect(() => {

        if (cargar2 == false) {
        var radiosAux=[];
        if(busquedaSO!=null && busquedaSO!=undefined && Object.entries(busquedaSO).length!=0){
            
            if(document.getElementById('radio1').value==busquedaSO.anp){
                document.getElementById('radio1').setAttribute("checked",true);
                //radiosAux[0]=1;
                document.getElementById('nombre_anp').value=busquedaSO.nombre_anp;

            }else if(document.getElementById('radio2').value==busquedaSO.anp){
                document.getElementById('radio2').setAttribute("checked",true);
                //radiosAux[0]=2;
                document.getElementById('nombre_anp').value='NA';
            }


            if(document.getElementById('radio3').value==busquedaSO.prof_unidad){
                //radiosAux[1]=1;
                document.getElementById('radio3').setAttribute("checked",true);

            }else if(document.getElementById('radio4').value==busquedaSO.prof_unidad){
                //radiosAux[1]=2;
                document.getElementById('radio4').setAttribute("checked",true);
            }


            if(document.getElementById('radio5').value==busquedaSO.prof_medida_con){
                //radiosAux[2]=1;
                document.getElementById('radio5').setAttribute("checked",true);

            }else if(document.getElementById('radio6').value==busquedaSO.prof_medida_con){
                //radiosAux[2]=2;
                document.getElementById('radio6').setAttribute("checked",true);

            }else if(document.getElementById('radio7').value==busquedaSO.prof_medida_con){
                //radiosAux[2]=3;
                document.getElementById('radio7').setAttribute("checked",true);

            }else if(document.getElementById('radio8').value==busquedaSO.prof_medida_con){
                //radiosAux[2]=4;
                document.getElementById('radio8').setAttribute("checked",true);
            }


            if(document.getElementById('radio9').value==busquedaSO.vistransver_unidad){
                //radiosAux[3]=1;
                document.getElementById('radio9').setAttribute("checked",true);

            }else if(document.getElementById('radio10').value==busquedaSO.vistransver_unidad){
                //radiosAux[3]=2;
                document.getElementById('radio10').setAttribute("checked",true);
            }


            if(document.getElementById('radio11').value==busquedaSO.vistransver_medida_con){
                //radiosAux[4]=1;
                document.getElementById('radio11').setAttribute("checked",true);

            }else if(document.getElementById('radio12').value==busquedaSO.vistransver_medida_con){
                //radiosAux[4]=2;
                document.getElementById('radio12').setAttribute("checked",true);

            }


            if(document.getElementById('radio13').value==busquedaSO.tempa_unidad){
                //radiosAux[5]=1;
                document.getElementById('radio13').setAttribute("checked",true);

            }else if(document.getElementById('radio14').value==busquedaSO.tempa_unidad){
                //radiosAux[5]=2;
                document.getElementById('radio14').setAttribute("checked",true);
            }


            if(document.getElementById('radio15').value==busquedaSO.tempa_medida_en){
                //radiosAux[6]=1;
                document.getElementById('radio15').setAttribute("checked",true);

            }else if(document.getElementById('radio16').value==busquedaSO.tempa_medida_en){
                //radiosAux[6]=2;
                document.getElementById('radio16').setAttribute("checked",true);
            }

            if(document.getElementById('radio17').value==busquedaSO.tempa_medida_con){
                //radiosAux[7]=1;
                document.getElementById('radio17').setAttribute("checked",true);

            }else if(document.getElementById('radio18').value==busquedaSO.tempa_medida_con){
                //radiosAux[7]=2;
                document.getElementById('radio18').setAttribute("checked",true);
            }


            if(document.getElementById('radio19').value==busquedaSO.tec_monitoreo){
                //radiosAux[8]=1;
                document.getElementById('radio19').setAttribute("checked",true);

            }else if(document.getElementById('radio20').value==busquedaSO.tec_monitoreo){
                //radiosAux[8]=2;
                document.getElementById('radio20').setAttribute("checked",true);
            }


            if(document.getElementById('radio21').value==busquedaSO.tipo_fondo){
                //radiosAux[9]=1;
                document.getElementById('radio21').setAttribute("checked",true);

            }else if(document.getElementById('radio22').value==busquedaSO.tipo_fondo){
                //radiosAux[9]=2;
                document.getElementById('radio22').setAttribute("checked",true);

            }else if(document.getElementById('radio23').value==busquedaSO.tipo_fondo){
                //radiosAux[9]=3;
                document.getElementById('radio23').setAttribute("checked",true);

            }else if(document.getElementById('radio24').value==busquedaSO.tipo_fondo){
                //radiosAux[9]=4;
                document.getElementById('radio24').setAttribute("checked",true);
            }

            //setRadios(radiosAux);
            setCargar2(true);
        }
    }

        if (data != null && data.hasOwnProperty('anp') ) {

            if(data.anp === 'NO')
                data['nombre_anp'] = 'NA';

        }

        infoJSON = {

            ...data
        }
        
        //console.log('Valor actual data')
        //console.log(data)

    }, [data]);

    useEffect(() => {

        //console.log("U Post");
        setError(false);
        
        if(post !=null && post !=undefined && Object.entries(post).length!=0){

            setEditar(true);
            setData(post);
            setSObs(post);

        }

    }, [post]);

    useEffect(() => {
        //console.log("U Put");
        setError(false);

        if(put !=null && put !=undefined && Object.entries(put).length!=0){

            setEditar(true);
            setData(put);
            setSObs(put);

        }

    }, [put]);

    const handleSubmit = (e) => {

        e.preventDefault();
        var campos_vacios='';

        //console.log('actualizar ' + actualizar);
        if (e.target.innerText == 'Enviar') {

            //console.log("Datos para enviar");
            //console.log(data);

            if (data !== null && data !== undefined && Object.entries(data).length !=0) {

                infoJSON = VerificarInfo2(data);

                //campos_vacios=CapturaVaciosE(infoJSON);

                if(infoJSON.nombre_playa=="")
                    campos_vacios='nombre_playa,';

                if (campos_vacios == '') {

                    //console.log("inforJSON Sanitizado")
                    //console.log(infoJSON)

                    if (!actualizar) {
                        //console.log('Post');
                        //console.log(infoJSON);
                        axios
                            .post(`${baseURL}/observador/${observer.id}/sitioObservado`, infoJSON)
                            .then((response) => {

                                setPost(response.data);
                                setBEnviar(false);
                                setMensajeR(1);
                                setDisableBT(false);

                            }
                            ).catch(error => {

                                //console.log(error);
                                setError(true);
                                setBEnviar(true);
                                setEditar(false);
                                setMensajeR(4);
                                setMensajeError(MensajeError2(error, infoJSON));
                                setDisableBT(true);
                            });

                    } else {

                        //console.log('Put');

                        axios
                            .put(`${baseURL}/observador/${observer.id}/sitioObservado/${infoJSON.id}`, infoJSON)
                            .then((response) => {

                                //console.log(response);
                                setPut(response.data);
                                setMensajeR(2);
                                setDisableBT(false);

                            }).catch(error => {

                                //console.log(error);
                                setBEnviar(true);
                                setEditar(false);
                                setError(true);
                                setMensajeR(4);
                                setMensajeError(MensajeError2(error, infoJSON));
                                setDisableBT(true);

                            });

                        setActualizar(false);
                    }
                    setError(false);
                    setBEnviar(false);
                    setEditar(true);

                }else{

                    setMensajeR(4);
                    setMensajeError(MensajeError(campos_vacios, 2));
                    return;
                }

            } else{

                setMensajeR(5);
                return;
            }


        } else if (e.target.innerText == 'Editar') {

            //console.log('clic Editar');

                setBEnviar(true);
                setEditar(false);

            if (error)
                setActualizar(false);
            else
                setActualizar(true);
        }
    }

    const handleReset = () => {

        //console.log('clic Eliminar');

        axios
            .delete(`${baseURL}/observador/${observer.id}/sitioObservado/${data.id}`)
            .then(() => {

                setMensajeR(3);
                setPost(null);

            });

        setBEnviar(true);
        setEditar(false);
        setActualizar(false);
        setDisableBT(true);
        document.getElementById('formL2').reset();
        setData({});
    }

    return (
        <div id='contentL'>
            <div className='div-standar2'>
                <h2>Informacion del Sitio Observado</h2>
                <br />
                <form id='formL2' onSubmit={handleSubmit} >
                    <div className="input-group input-group-lg mb-4">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Fecha</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='fecha' name='fecha'
                            onChange={handleInput} disabled={editar} autoComplete="off"
                            placeholder='Formato: YYYY-MM-DD (año-mes-dia)'
                            value={data.fecha}
                        />
                    </div>
                    <div className="input-group input-group-lg mb-5">
                        <div className='col-12 form-label fs-4' forhtml='nombre_playa'>
                            Nombre del Sitio o Playa
                        </div>
                        <input type="text" className="form-control rounded" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='nombre_playa' name='nombre_playa'
                            onChange={handleInput} disabled={editar} autoComplete="off" 
                            value={data.nombre_playa}
                            />
                    </div>
                    <div className='col-12 form-label fs-4' forhtml='nombre_playa'>
                            Inicio Coordenadas GPS
                        </div>
                    <div className="input-group input-group-lg mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Latitud (N/+)</span>
                        <input type="number" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='cgpsinicio_lat' name='cgpsinicio_lat'
                            onChange={handleInput} disabled={editar} autoComplete="off" step="0.3"
                            value={data.cgpsinicio_lat} />
                    </div>
                    <div className="input-group input-group-lg mb-4">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Longitud (W/-)</span>
                        <input type="number" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='cgpsinicio_long' name='cgpsinicio_long'
                            onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" 
                            value={data.cgpsinicio_long}/>
                    </div>
                    <div className='col-12 form-label fs-4' forhtml='nombre_playa'>
                            Fin Coordenadas GPS
                        </div>
                    <div className="input-group input-group-lg mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Latitud (N/+)</span>
                        <input type="number" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='cgpsfin_lat' name='cgpsfin_lat'
                            onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" 
                            value={data.cgpsfin_lat}/>
                    </div>
                    <div className="input-group input-group-lg mb-5">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Longitud (W/-)</span>
                        <input type="number" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='cgpsfin_long' name='cgpsfin_long'
                            onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" 
                            value={data.cgpsfin_long}/>
                    </div>
                    <div className="input-group input-group-lg mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-lg">Pais</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='pais' name='pais'
                            onChange={handleInput} disabled={editar} autoComplete="off" 
                            value={data.pais}
                            />
                    </div>
                    <div className="input-group input-group-lg mb-5">
                        <div className='col-12 form-label fs-4' forhtml='estado_provincia'>
                            Estado o Provincia
                        </div>
                        <input type="text" className="form-control rounded" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" id='estado_provincia' name='estado_provincia'
                            onChange={handleInput} disabled={editar} autoComplete="off"
                            value={data.estado_provincia} 
                            />
                    </div>
                    <div className="row mx-auto mb-5">
                        <div className='col-12 form-label fs-4' forhtml='estado_provincia'>
                            Área Natural Protegida
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio1'
                                name='anp' value='SI'
                                onClick={handleInput}
                                disabled={editar} 

                                />
                            <label className="form-label" forhtml="radio1" >SI</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio2'
                                name='anp' value='NO'
                                onClick={handleInput}
                                disabled={editar}


                                />
                            <label className="form-label" forhtml="radio2" >NO</label>
                        </div>
                        <div className="input-group input-group-lg ">
                            <div className='col-12 form-label fs-4 mt-4' forhtml='nombre_anp'>
                                Nombre Área Natural Protegida
                            </div>
                            <input type="text" className="form-control rounded" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id='nombre_anp' name='nombre_anp'
                                onChange={handleInput} disabled={editar} autoComplete="off"
                                value={data.nombre_anp}
                            />
                        </div>
                    </div>
                    <div className='col-12 form-label fs-3 mb-5' forhtml='estado_provincia'>
                            Campos para Monitoreo de Área Marina
                    </div>
                    <hr/>
                    <div className="row mx-auto mb-3">
                        <div className="input-group input-group-lg mb-4">
                            <div className='col-12 form-label fs-4 mt-2' forhtml='prof_valor'>
                                Profundidad
                            </div>
                            <input type="number" className="form-control rounded" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id='prof_valor' name='prof_valor'
                                onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" 
                                value={data.prof_valor}
                            />
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='prof_unidad'>
                            Unidad
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio3'
                                name='prof_unidad' value='m'
                                onClick={handleInput}
                                disabled={editar} 

                                />
                            <label className="form-label" forhtml="radio3" >m</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio4'
                                name='prof_unidad' value='ft'
                                onClick={handleInput}
                                disabled={editar}  
   
                                />
                            <label className="form-label" forhtml="radio4" >ft</label>
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='prof_medida_con'>
                            Medida con
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio5'
                                name='prof_medida_con' value='Buceo'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio5" >Buceo</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio6'
                                name='prof_medida_con' value='Sonar'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio6" >Sonar</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio7'
                                name='prof_medida_con' value='Cuerda'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio7" >Cuerda</label>
                        </div>
                        <div className='col mb-3'>
                            <input className="form-check-input me-1" type='radio' id='radio8'
                                name='prof_medida_con' value='Visual'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio8" >Visual</label>
                        </div>
                        <hr/>
                    </div>
                    <div className="row mx-auto mb-3">
                        <div className="input-group input-group-lg mb-4">
                            <div className='col-12 form-label fs-4 mt-2' forhtml='vistransver_valor'>
                                Visibilidad / Transparencia - Vertical
                            </div>
                            <input type="number" className="form-control rounded" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id='vistransver_valor' name='vistransver_valor'
                                onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" 
                                value={data.vistransver_valor}
                            />
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='vistransver_unidad'>
                            Unidad
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio9'
                                name='vistransver_unidad' value='m'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio9" >m</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio10'
                                name='vistransver_unidad' value='ft'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio10" >ft</label>
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='vistransver_medida_con'>
                            Medida con
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio11'
                                name='vistransver_medida_con' value='Disco Sechi'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio11" >Disco Sechi</label>
                        </div>
                        <div className='col mb-3'>
                            <input className="form-check-input me-1" type='radio' id='radio12'
                                name='vistransver_medida_con' value='Visual'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio12" >Visual</label>
                        </div>
                        <hr/>
                    </div>
                    <div className="row mx-auto mb-3">
                        <div className="input-group input-group-lg mb-4">
                            <div className='col-12 form-label fs-4 ' forhtml='tempa_valor'>
                                Temperatura del Agua
                            </div>
                            <input type="number" className="form-control rounded" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id='tempa_valor' name='tempa_valor' step="0.3" 
                                onChange={handleInput} disabled={editar} autoComplete="off"
                                value={data.tempa_valor}
                            />
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='tempa_unidad'>
                            Unidad
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio13'
                                name='tempa_unidad' value='⁰C'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio13" >⁰C</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio14'
                                name='tempa_unidad' value='⁰F'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off"
                                />
                            <label className="form-label" forhtml="radio14" >⁰F</label>
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='tempa_medida_en'>
                            Medida en
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio15'
                                name='tempa_medida_en' value='Superficie'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio15" >Superficie</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio16'
                                name='tempa_medida_en' value='Fondo'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio16" >Fondo</label>
                        </div>
                        <div className='col-12 form-label fs-4' forhtml='tempa_medida_con'>
                            Medida con
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio17'
                                name='tempa_medida_con' value='Buceo'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio17" >Buceo</label>
                        </div>
                        <div className='col mb-3'>
                            <input className="form-check-input me-1" type='radio' id='radio18'
                                name='tempa_medida_con' value='Termómetro'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio18" >Termómetro</label>
                        </div>
                        <hr/>
                    </div>
                    <div className="row mx-auto mb-3">
                        <div className='col-12 form-label fs-4' forhtml='tec_monitoreo'>
                            Técnica de Monitoreo
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio19'
                                name='tec_monitoreo' value='Manta tow'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio19" >Manta tow</label>
                        </div>
                        <div className='col mb-3'>
                            <input className="form-check-input me-1" type='radio' id='radio20'
                                name='tec_monitoreo' value='Buceo Libre'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio20" >Buceo Libre</label>
                        </div>
                        <hr/>
                    </div>
                    <div className="row mx-auto mb-3">
                        <div className='col-12 form-label fs-4' forhtml='tipo_fondo'>
                            Tipo de Fondo
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio21'
                                name='tipo_fondo' value='Arrecife'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio21" >Arrecife</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio22'
                                name='tipo_fondo' value='Pastos Marinos'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio22" >Pastos Marinos</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio23'
                                name='tipo_fondo' value='Algas'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio23" >Algas</label>
                        </div>
                        <div className='col'>
                            <input className="form-check-input me-1" type='radio' id='radio24'
                                name='tipo_fondo' value='Arena'
                                onClick={handleInput}
                                disabled={editar} autoComplete="off" 
                                />
                            <label className="form-label" forhtml="radio24" >Arena</label>
                        </div>
                        <hr/>
                    </div>
                    <div className='row mx-auto'>
                        {
                            bEnviar ?
                                <div className='col-12'>
                                    <button id='bsubmit' type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}>
                                        Enviar
                                    </button>
                                </div>
                                :
                                <>
                                    <div className='col-6 mx-auto'>
                                        <button type="button"
                                            className="btn btn-primary me-1"
                                            onClick={handleSubmit}>
                                            Editar
                                        </button>
                                        <button type="button"
                                            className="btn btn-danger "
                                            onClick={handleReset}>
                                            Eliminar
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                    <MensajeRespuesta mensajeR={mensajeR} mensajeError={mensajeError}/>
                </form>
            </div>
        </div>
    )
}
