export default [

    {
      title1: 'Hora'
    },
    {
      title1: 'Actividad'
    },
    {
      title1: 'Coordenadas GPS',
      titles2:'Latitud,Longitud'
    },
    {
      title1:'Número del Nido'
    },
    {
      title1: 'Especie'
    },
    {
      title1: 'MorfometríaLSCC Largo curvo estándar del carapacho'
    },
    {
      title1: 'Morfometría ACC Ancho curvo del carapacho'
    },    
    {
      title1: 'Estado físico de la tortuga o daño presente',
    },
    {
      title1: 'Ancho de cabeza (AC)'
    },
    /*{
        title1:'Zona  balizas: Ubicación del nido respecto a las balizas'
    },*/
    {
      title1: "Número o conteo de huevos"
    },
    {
      title1: "Nido en perfil: Ubicación del nido en el perfil de playa"
    },
    {
      title1: "Temperatura del nido"
    },
    {
      title1: "Profundidad del nido"
    },
    {
      title1: "Marca o Tags"
    },
    {
      title1: "Observaciones"
    },

  ]
