import React, { useEffect, useState } from 'react'
import '../style/InputTypeTextT.css'

export const InputTypeTextT = ({ dataType, data, setData, editar,
                                mismo, anp, registroE }) => {

    let nameType = dataType.toLowerCase();

    useEffect(() => {

        if (registroE != null && registroE != undefined && Object.entries(registroE).length != 0) {

            //console.log("RegistroE")
            //console.log(registroE)

            Object.keys(registroE).map((elem, index) => {

                if (elem == nameType)
                    document.getElementById(nameType).value = Object.values(registroE)[index];
            })
        }

    }, [])

    const handleInput = e => {

        var propiedad = '';
        var valor = '';

        propiedad = e.target.name.toLowerCase();
        valor=e.target.value;

        const nodoJSON = {

            ...data,
            [propiedad]: valor,

        }

        setData(nodoJSON);
        //console.log(data);
    }

    const handleBlur = e => {

        const nodoJSON = {

            ...data,
            [e.target.name]: e.target.value,

        }

        setData(nodoJSON)

    }

    return (
        <>
            <div className="input-group input-group-lg">
                {
                    nameType === 'temp_nido' ||
                    nameType === 'prof_nido' ||
                    nameType === 'prof_valor' ||
                    nameType === 'vistransver_valor' ||
                    nameType === 'tempa_valor' ||
                    nameType === 'talla_estimada' ||
                    nameType === 'latitud (n/+)' ||
                    nameType === 'longitud (w/-)' ||
                    nameType === 'morfometria_lscc' ||
                    nameType === 'morfometria_acc' ||
                    nameType === 'ancho_cabeza' ||                    
                    nameType === 'latitud' ||
                    nameType === 'longitud' ?
                        <>
                            {
                             nameType.includes('_') ?
                             <></>
                             :
                             <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                            }
                            <input type="number" className="form-control" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                onChange={handleInput} disabled={editar} autoComplete="off" step="0.3" />
                            
                        </>

                        :

                        nameType === 'edad' ||
                        nameType === 'num_nido' ||
                        nameType === 'num_huevos' ||
                        nameType === 'num_crias_vivas' ||
                        nameType === 'num_huevos_eclo' ||
                        nameType === 'num_crias_muertas' ||
                        nameType === 'num_huevos_sin_des' ||
                        nameType === 'huevos_dep' ||
                        nameType === 'total_huevos_no_eclo' ||
                        nameType === 'huevos_infertiles' ||
                        nameType === 'num_total_huevos' ||
                        nameType === 'crias_sup_corral'?

                            <>{
                                nameType.includes('_') ?
                                <></>
                                :
                                <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                              }
                                <input type="number" className="form-control" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                    onChange={handleInput} disabled={editar} autoComplete="off" />
                            </>

                            :

                            nameType === 'email' ?
                                <>
                                    <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                    <input type="text" className="form-control" aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                        onChange={handleInput} disabled={editar} autoComplete="off" />
                                </>
                                :
                                nameType === "nombre_anp" ?
                                    anp !== 'NA' ?
                                        <>{
                                            nameType.includes('_') ?
                                            <></>
                                            :
                                            <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                          }
                                            <input type="text" className="form-control" aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                                onChange={handleInput} disabled={editar} autoComplete="off" />
                                        </>
                                        :
                                        <></>
                                    :
                                    nameType === 'fecha' || nameType === 'fecha_ec_ex' ?
                                        <>
                                          {
                                            nameType.includes('_') ? 
                                            <></>
                                            :
                                            <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                          }
                                            <input type="text" className="form-control" aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                                onChange={handleInput} disabled={editar} autoComplete="off"
                                                placeholder='Formato: YYYY-MM-DD (año-mes-dia)'
                                            />
                                        </>
                                        :
                                        nameType === 'hora' ?
                                            <>
                                                {
                                                    nameType.includes('_') ?
                                                        <></>
                                                        :
                                                        <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                                }
                                                <input type="text" className="form-control" aria-label="Sizing example input"
                                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                                    onChange={handleInput} disabled={editar} autoComplete="off"
                                                    placeholder='Formato: HH:mm ejemplo: 18:30'
                                                />
                                            </>
                                            :
                                            <>
                                                {
                                                    nameType.includes('_') ?
                                                        <></>
                                                        :
                                                        <span className="input-group-text" id="inputGroup-sizing-lg">{dataType}</span>
                                                }
                                                <input type="text" className="form-control" aria-label="Sizing example input"
                                                    aria-describedby="inputGroup-sizing-lg" id={nameType} name={nameType}
                                                    onChange={handleInput} disabled={editar} autoComplete="off" />
                                            </>
                }
            </div>
            <br />
        </>
    )
}