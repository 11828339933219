import React, { useEffect, useState } from 'react'
import { FormTable } from '../../components/FormTable';
import { ShowTable } from '../../components/ShowTable'
import tableFields1 from '../config/TableFields1';
import headersTable1 from '../config/TableHeaders1';
import '../../style/ContentDeployer.css'

export const ContentDeployerC1 = ({sObs,moniTablas,setEnviado,numtabla}) => {

    const [visible,setVisible] =useState(false);
    const [accion, setAccion] = useState('');
    const [registros,setRegistros]=useState([]);
    const [registroE,setRegistroE]=useState({});
    const [mensajeR,setMensajeR]=useState(0);
    const [mensajeA,setMensajeA]=useState(0);
    const [mensajeError, setMensajeError] = useState('');
    const [data, setData] = useState({});
    const [cargar,setCargar]=useState(false);

    if (cargar == false) {
        if (moniTablas[0] != null && moniTablas[0] != undefined && moniTablas[0].length != 0) {

            setRegistros(moniTablas[0]);
            setCargar(true);
        }
    }

    useEffect(() => {

        //console.log("U Data")
        //console.log(data);

    }, [data]);

    useEffect(() => {
        
        if (visible){
            document.getElementsByClassName("form-control").namedItem("hora").focus();

            /*actividad:'',
            hora:'',
            latitud:0,
            longitud:0,
            num_nido:0,
            especie:'',
            morfometria_lscc:'',
            morfometria_acc:'',
            estado_fisico:'',
            ancho_cabeza:0,
            num_huevos:'',
            nido_perfil:'',
            temp_nido:0,
            prof_nido:0,
            marca_tags:'',
            tortuga_gps:'',
            observaciones:'',*/

        }

    }, [visible])    

    return (
            <div id='content'>
                <br/>
                <ShowTable headers={headersTable1} 
                    setAccion={setAccion} 
                    setVisible={setVisible}
                    setRegistroE={setRegistroE} 
                    sObs={sObs} 
                    registros={registros}
                    setRegistros={setRegistros}
                    mensajeR={mensajeR}
                    setMensajeR={setMensajeR}
                    mensajeA={mensajeA}
                    setMensajeA={setMensajeA}
                    mensajeError={mensajeError}
                    tabla={'mndhas'}
                    setEnviado={setEnviado}
                    data={data}
                    setData={setData}                 
                    />
                {
                    visible? <FormTable titulo={"Características monitoreadas"} 
                                        campos={tableFields1} 
                                        accion={accion}               
                                        sObs={sObs} 
                                        setRegistros={setRegistros}
                                        setVisible={setVisible}
                                        visible={visible}
                                        registroE={registroE}
                                        mensajeR={mensajeR}
                                        setMensajeR={setMensajeR}
                                        setMensajeA={setMensajeA}
                                        mensajeError={mensajeError}
                                        setMensajeError={setMensajeError}
                                        tabla={'mndhas'}  
                                        tipotabla={1}
                                        setEnviado={setEnviado}
                                        data={data}
                                        setData={setData}
                                       />
                        :
                        <></>
                }
                <br/>
            </div>
    )
}
