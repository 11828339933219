export default [
  {
    tipo: "titulo1",
    campo: "Coordenadas del nido"
  },
  {
    tipo: "texto",
    campo: "Latitud"
  },
  {
    tipo: "texto",
    campo: "Longitud"
  },
  {
    tipo: "espacio",
    campo: "espacio"
  },
  {
    tipo:'ayuda',
    mensaje:'Especie: tortuga verde o blanca (Chelonia mydas), '+
            'el carey (Eretmochelys imbricata), caguama o caguamo (Caretta caretta), '+
            'tortuga golfina (Lepidochelys olivacea), el tinglar, (Dermochelys coriacea)'
  },
  {
    tipo: "texto",
    campo: "Especie"
  },
  {
    tipo: "titulo1",
    campo: "Número de nido"
  },
  {
    tipo: "texto",
    campo: "Num_nido"
  },
  {
    tipo: "titulo1",
    campo: "Nido en perfil: Ubicación del nido en el perfil de playa"
  },
  {
    tipo:'ayuda',
    mensaje:'a: anteplaya, b: posplaya (berma), c: duna'
  },
  {
    tipo: "texto",
    campo: "Nido_perfil"
  },
  {
    tipo: "titulo1",
    campo: "Cóndicion del nido"
  },
  {
    tipo:'ayuda',
    mensaje:' int: intacto, nac: nacido, d: depredado, '+ 
            'ind: inundado, saq: saqueo, er: erosión, ' + 
            'mar: mareas extraordinarias, sar: recale de sargazo'
  },
  {
    tipo: "texto",
    campo: "Condicion_nido"
  },
  {
    tipo: "titulo1",
    campo: "Número de huevos por nido"
  },
  {
    tipo: "texto",
    campo: "Num_huevos_nido"
  },
  {
    tipo: "espacio",
    campo: "espacio"
  }
]