import React, { useEffect } from 'react'

export const TextAreaT = ({dataType, data, 
                          setData, editar, 
                          registroE,}) => {

    var nameType = dataType.toLowerCase();

    useEffect(() => {

        if (registroE != null && registroE != undefined && Object.entries(registroE).length != 0) {

            //console.log("AreaText")
            //console.log(registroE)

            Object.keys(registroE).map((elem, index) => {

                if (elem == nameType)
                    document.getElementById(nameType).value = Object.values(registroE)[index];
            })
        }

    }, [])

    const handleInput=(e)=>{

        const nodoJSON = {

            ...data,
            [e.target.name]: e.target.value,

        }

        setData(nodoJSON)
    }

  return (
      <>
          <div className="input-group input-group-lg">
              <textarea className="form-control" id={nameType} name={nameType} onChange={handleInput}
                  disabled={editar} autoComplete="off" rows="3"></textarea>
          </div>
          <br />
      </>
  )
}
