import React from 'react'
import titulos from '../config/T4HeadersFields'
import '../../MoniTablas/MonAreaMarina/style/MonAreaMarina.css'

export const MonAreaMarina = ({tabla4}) => {
    return (
        <div>
            {
                tabla4 !=null && Object.entries(tabla4).length != 0 ?
                    <div className='show-scroll'>
                        <table id='mam-table' className="table table-bordered">
                            <thead>
                                <tr>
                                    {
                                        titulos.map((elem,index) => {

                                            return (
                                                elem.titles2 != null || elem.titles2 != undefined ?
                                                    elem.titles2.split(',').map(elem2 => <td key={index} >{elem2}</td>)
                                                    :
                                                    <td key={index+'h2mam'} >{elem.title1}</td>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tabla4.map((elem,index)=>{

                                    return(
                                        <tr>
                                            <td key={elem.id+'c31mam'} >{elem.hora}</td>
                                            <td key={elem.id+'c32mam'} >{elem.especie}</td>
                                            <td key={elem.id+'c33mam'} >{elem.observada_capturada}</td>
                                            <td key={elem.id+'c34mam'} >{elem.actividad}</td>
                                            <td key={elem.id+'c35mam'} >{elem.talla_estimada}</td>
                                            <td key={elem.id+'c36mam'} >{elem.morfometria_lscc}</td>
                                            <td key={elem.id+'c37mam'} >{elem.morfometria_acc}</td>
                                            <td key={elem.id+'c38mam'} >{elem.sexo}</td>
                                            <td key={elem.id+'c39mam'} >{elem.num_marcas_tags}</td>
                                            <td key={elem.id+'c310mam'} >{elem.estado_fisico}</td>
                                            <td key={elem.id+'c311mam'} >{elem.observaciones}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <p>Sin Datos</p>
            }
            <br />
    </div >
  )
}
