import React from 'react'

export const Label1 = ({title}) => {
  return (
    <div className='div-title'>
      <hr/><br/>
        <h3>{title}</h3>
    </div>
  )
}
