const VerificarInfo = (infoJSON) => {

    let campo_v = '';
    let caracteres = '';

    Object.values(infoJSON).forEach((valor, index) => {

        if (Object.keys(infoJSON)[index] != 'id' && Object.keys(infoJSON)[index] != 'sob') {

            if (typeof valor == 'number') 
                valor = valor.toString();
    

            if (Object.keys(infoJSON)[index] !== 'email') {

                caracteres = valor
                    .split('')
                    .filter((elem) => elem !== '.' && elem !== ',' && elem !== ';' && elem !== '<' &&
                        elem !== '>' && elem !== '=' && elem !== '/' && elem !== '*' &&
                        elem !== '%' && elem !== String.fromCharCode(47) && elem !== String.fromCharCode(92)
                        && elem !== '?' && elem !== String.fromCharCode(39))
            } else {

                caracteres = valor
                    .split('')
                    .filter((elem) => elem !== ',' && elem !== ';' &&
                        elem !== '=' && elem !== '/' && elem !== String.fromCharCode(47) &&
                        elem !== '*' && elem !== '%' && elem !== String.fromCharCode(39)) //47 es '\' y 39 es '
            }

            infoJSON[Object.keys(infoJSON)[index]] = caracteres.join('');
            
        }
    })

    return infoJSON;
}

export default VerificarInfo;