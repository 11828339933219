export default [
    {
        titulo:"Información del Observador"
    },
    {
        titulo:"Información del Sitio Observado"
    },
    {
        titulo:"Tabla monitoreo nocturno para la detección de hembras anidadoras"
    },
    {
        titulo:"Tabla monitoreo diurno para la detección de nidos"
    },
    {
        titulo:"Tabla monitoreo de nidos en eclosión o eclosionados"
    },
    {
        titulo:"Tabla monitoreo en área marina"
    }
]