import React, { useEffect, useState } from 'react'
import axios from "axios";

export const useForm = (initialForm, validateForm, setPost, setPut, 
                        usuarioE, setUsuarioE, actualizar, setActualizar,
                        setErrorBD) => {

    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    //var baseURL = "https://localhost:8080/usuarios";
    var baseURL ="https://monitortuga.monitoreomarinocosterord.com:8442/usuarios"
    const handleChange = (e) => {

        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value
        });

        //console.log(form)
    }

    const handleBlur = (e) => {

        handleChange(e);
        setErrors(validateForm(form));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //console.log("Errores")
        //console.log(errors)
        //console.log('Datos a enviar')
        //console.log(form);

        if (Object.entries(errors) == 0) {
            if (actualizar) {

                //console.log('Put')

                axios
                    .put(`${baseURL}/${usuarioE.id}`, form)
                    .then((response) => {

                        setPut(response.data);
                        setForm(initialForm);
                        setUsuarioE({});
                        setActualizar(false);
                        setErrorBD({});

                    })
                    .catch(error => {

                        //console.log(error);
                        setErrorBD(error);

                    });

            } else {

                //console.log('Post');

                axios
                    .post(baseURL, form)
                    .then((response) => {

                        setPost(response.data);
                        setForm(initialForm);
                        setErrorBD({});

                    }).catch(error => {

                        //console.log(error);
                        setErrorBD(error);

                    });
            }
        }
    }

    return {
        form,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit
    };
}
