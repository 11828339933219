export const CrearVacios=(infoJSON,tipotabla)=>{

    var aux=false;

    if (tipotabla == 1) {


        const propiedades = ['actividad', 'latitud', 'longitud', 'num_nido', 'especie', 'morfometria_lscc',
            'morfometria_acc', 'estado_fisico', 'ancho_cabeza', 'num_huevos', 'nido_perfil', 'temp_nido',
            'prof_nido', 'marca_tags', 'tortuga_gps', 'observaciones'];

        propiedades.map(pro => {

            Object.keys(infoJSON).map(elem => {

                if (pro == elem) {
                    aux = true;
                }

            });

            if (!aux) {

                if (pro != 'latitud' && pro != 'longitud' && pro != 'num_nido' &&
                    pro != 'morfometria_lscc' && pro != 'morfometria_acc' &&
                    pro != 'ancho_cabeza' && pro != 'num_huevos' && pro != 'temp_nido' &&
                    pro != 'prof_nido') {

                    infoJSON = { ...infoJSON, [pro]: '', };
                } else {

                    infoJSON = { ...infoJSON, [pro]: 0, };
                }
            }

            aux = false;

        });

    } else if (tipotabla == 2) {

        const propiedades = ['latitud', 'longitud', 'condicion_nido', 'especie', 
                             'nido_perfil','num_huevos_nido','num_nido'];

        propiedades.map(pro => {

            Object.keys(infoJSON).map(elem => {

                if (pro == elem) {
                    aux = true;
                }

            });

            if (!aux) {

                if (pro != 'latitud' && pro != 'longitud' && pro != 'num_nido' &&
                    pro != 'num_huevos_nido' ) {

                    infoJSON = { ...infoJSON, [pro]: '', };
                } else {

                    infoJSON = { ...infoJSON, [pro]: 0, };
                }
            }

            aux = false;

        });

    } else if (tipotabla == 3) {

        const propiedades = ['num_nido','especie','num_crias_vivas', 'num_huevos_eclo', 
                             'num_crias_muertas','num_huevos_sin_des_inf','huevos_dep',
                             'total_huevos_no_eclo', 'num_total_huevos','crias_sup_corral',
                             'riesgo_perdida'];

        propiedades.map(pro => {

            Object.keys(infoJSON).map(elem => {

                if (pro == elem) {
                    aux = true;
                }

            });

            if (!aux) {

                if (pro != 'num_nido' && pro!='num_crias_vivas' && pro!= 'num_huevos_eclo'&&
                    pro != 'num_crias_muertas' && pro!='num_huevos_sin_des_inf' && pro!='huevos_dep' && 
                    pro != 'total_huevos_no_eclo' && pro !='num_total_huevos' && pro!='crias_sup_corral') {

                    infoJSON = { ...infoJSON, [pro]: '', };
                } else {

                    infoJSON = { ...infoJSON, [pro]: 0, };
                }
            }

            aux = false;

        });

    }else if(tipotabla == 4){

        const propiedades = ['especie','observada_capturada','actividad', 'talla_estimada', 
                             'morfometria_lscc','morfometria_acc','sexo',
                             'num_marcas_tags', 'estado_fisico','observaciones'];

        propiedades.map(pro => {

            Object.keys(infoJSON).map(elem => {

                if (pro == elem) {
                    aux = true;
                }

            });

            if (!aux) {

                if (pro != 'talla_estimada'  && pro!='morfometria_lscc' && 
                    pro!= 'morfometria_acc'  && pro != 'num_marcas_tags' ) {

                    infoJSON = { ...infoJSON, [pro]: '', };
                } else {

                    infoJSON = { ...infoJSON, [pro]: 0, };
                }
            }

            aux = false;

        });

    }

    return infoJSON;

}