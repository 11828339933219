export default [
    {
      title1:'Número del Nido'
    },
    {
      title1:'Fecha de eclosión o exhumación'
    },
    {
      title1: 'Especie'
    },
    {
      title1: 'Número de crías vivas o Neonatos vivos'
    },
    {
      title1: 'Número de huevos que han eclosionado (cascarones)'
    },
    {
      title1: 'Número de crías muertas o Neonatos muertos'
    },
    {
      title1: "Número de huevos sin desarrollo aparente (infértiles)"
    },
    {
      title1: "Huevos Depredados"
    },
    {
      title1: "Total de Huevos no eclosionados"
    },
    /*{
      title1: "Huevos Infertiles"
    },*/
    {
      title1: "Número Total de Huevos"
    },
    {
      title1: "Crías en superficie (corral)"
    },
    {
      title1: "Riesgos en nido o Pérdida del nido: inundación, saqueo, depredación, "+ 
              "erosión, mareas extraordinarias, recale de sargazo"
    },
  ]
