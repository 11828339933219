import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import axios from "axios";
import { ButtonHome } from '../../Home/components/ButtonHome';
import '../style/ProfileUsuario.css'
import perfilU from '../../Imagenes/PerfilU.jpg'

export const ProfileUsuario = () => {

  //var baseURL = "http://localhost:8080/usuarios";
  var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/usuarios";
  
  const [usuario, setUsuario] = useState({});

  const cookies = new Cookies();
  var id = ''
  id = cookies.get('IdU');

  if (Object.entries(usuario).length == 0) {
    axios
      .get(`${baseURL}/Id/${id}`)
      .then((response) => {

        setUsuario(response.data);

      }).catch(error => {/*console.log(error)*/});
  }

  return (
    <div className="container text-center p-1">
      <ButtonHome />
      <br />
      <div  id="datosU"  className='col-8 mx-auto  rounded p-4 shadow-lg  mb-2 '>
        <div className='row mx-auto'>
          <h2>Perfil de Usuario</h2>
        </div>
        <div className='row mb-2 '>
          <img src={perfilU} alt='No se encuentra imagen'
            id='imagen-perfil'
            className='shadow p-1 mb-2 rounded mt-2' />
        </div>
        <div className="row gy-2 mx-auto">
          <div className='col-9 mx-auto'>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Nombre</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.nombre}</div>
              </div>
            </div>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Edad</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.edad}</div>
              </div>
            </div>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Profesión</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.profesion}</div>
              </div>
            </div>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Email</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.email}</div>
              </div>
            </div>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Usuario</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.usuario}</div>
              </div>
            </div>
            <div className='row '>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">Tipo de Usuario</div>
              </div>
              <div className="col-6 shadow p-1 mb-2 rounded">
                <div className="p-3 fs-5">{usuario.tipoU}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
