import React, { useEffect, useState } from 'react'
import '../style/DeployerL.css'

export const DeployerL = ({ titulo, contentDeployer, disableBL, 
                            setDisableO, contentDeployer2,busquedaO,
                            setBusquedaSO}) => {

    const [deploy, setDeploy] = useState(false);
    const [cargar,setCargar]=useState(false);
    const [sitiosObs,setSitiosObs]=useState([]);
    const handleDep = () => setDeploy(!deploy)

    var id=0;

    if (cargar == false) {
        if (busquedaO != null && busquedaO != undefined && Object.entries(busquedaO).length != 0) {

            setDeploy(true);
            setCargar(true);
        }
    }

    useEffect(() => {

        if(deploy){
            
            document.getElementsByClassName("form-control").namedItem("fecha").focus();
            setSitiosObs(busquedaO.sob);
            setDisableO(true);

        }

    }, [deploy])

    const handleSelect=(e)=>{

        id=e.target.value;
        sitiosObs.map(elem=>{

            if(elem.id == id)
                setBusquedaSO(elem);
        });

    };

    return (
        <div>
            <br />
            {
                disableBL ?
                    <div className='div-buttond'>
                        <button id='p_buttond' className='btn btn-primary'  onClick={handleDep} disabled={disableBL}>{titulo}</button>
                    </div>
                    :
                    <div className='div-button'>
                        <button id='p_button' onClick={handleDep} disabled={disableBL}>{titulo}</button>
                        {
                            sitiosObs == null || sitiosObs == undefined ?
                                <></>
                                :
                                sitiosObs.length == 0 ?
                                    <>
                                    </>
                                    :
                                    <div className='col-5 mx-auto mt-2'>
                                    <select className="form-select" onChange={handleSelect} >
                                        <option defaultValue>Seleccionar Sitio Observado</option>
                                            {sitiosObs.map(elem => <option key={elem.id} value={elem.id}>{elem.nombre_playa}
                                            </option>)}
                                    </select>
                                    </div>
                        }
                    </div>
            }
            {
                deploy ?
                    <>
                    {
                        contentDeployer
                    }
                    {
                        contentDeployer2
                    }
                    </>
                    : <></>
            }
            <br />
        </div>
    )
}

