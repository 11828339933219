import React, { useEffect, useState } from 'react'
import '../../Observador/style/FormObserver.css';
import VerificarInfo from '../../Funciones/VerificarInfo';
import axios from "axios";
import { MensajeError } from '../../Funciones/MensajeError';
import { MensajeRespuesta } from '../../Sistema/components/MensajeRespuesta';

import { Label1 } from '../components/Label1';
import { Label2 } from '../components/Label2';
import { CapturaVaciosF} from '../../Funciones/CapturaVaciosF';
import { InputTypeTextO } from './InputTypeTextO';

export const FormObserver = ({ titulo, campos, setDisableBL, setObserver,busquedaOC }) => {

    const [data, setData] = useState({});
    const [editar, setEditar] = useState(false);
    const [bEnviar, setBEnviar] = useState(true);
    const [actualizar, setActualizar] = useState(false);
    const [post, setPost] = useState(null);
    const [put, setPut] = useState(null);
    const [error, setError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeR, setMensajeR] = useState(0);
    const [dataObserver, setDataObserver] = useState({});
    const [cargar, setCargar] = useState(false);

    var infoJSON;
    //var baseURL = "http://localhost:8080/observadores";
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/observadores";

    if (cargar == false) {

            if (busquedaOC != null && busquedaOC != undefined && Object.entries(busquedaOC).length != 0) {

                //console.log('Busqueda FormObserver')
                //console.log(busquedaOC);
                setData(busquedaOC);
                setObserver(busquedaOC);
                setEditar(true);
                setBEnviar(false);
                setDataObserver(busquedaOC);
                setCargar(true);
                setDisableBL(false);
            }
    }

    useEffect(() => {

        infoJSON = {
            ...data
        }

        //console.log(data)
    }, [data]);

    useEffect(() => {

        //console.log("U Post");
        setError(false);       
        
        if(post!=null && Object.entries(post).length!=0){

            setEditar(true);
            setData(post);
            setObserver(post);
            setDataObserver(post);
        }

    }, [post]);

    useEffect(() => {

        //console.log("U Put");
        setError(false);

        if(put!=null && Object.entries(put).length!=0){
            
            setEditar(true);
            setData(put);
            setObserver(put);
            setDataObserver(put);

        }

    }, [put]);

    const handleSubmit = (e) => {

        e.preventDefault();
        var campos_vacios='';

        if (e.target.innerText == 'Enviar') {

            if (data != null && data != undefined && Object.entries(data).length !== 0) {

                infoJSON = VerificarInfo(data, campos, setDisableBL);

                campos_vacios=CapturaVaciosF(infoJSON);

                //console.log("campos vacios");
                //console.log(campos_vacios);

                if ( campos_vacios == '') {

                    //console.log("Datos para enviar")
                    //console.log(data);

                    if (!actualizar) {
                        //console.log('Post')

                        axios
                            .post(baseURL, data)
                            .then((response) => {

                                //console.log(response);
                                setPost(response.data);
                                setBEnviar(false);
                                setMensajeR(1);
                                setDisableBL(false);

                            }).catch(error => {

                                //console.log(error);
                                setBEnviar(true);
                                setEditar(false);
                                setError(true);
                                setMensajeR(4);
                                setMensajeError(MensajeError(error, 1));
                                setDisableBL(true);
                            });

                    } else {
                        //console.log('Put')
                        
                        axios
                            .put(`${baseURL}/${infoJSON.id}`, infoJSON)
                            .then((response) => {

                                //console.log(response);
                                setPut(response.data);
                                setMensajeR(2);
                                setBEnviar(false);

                            })
                            .catch(error => {

                                //console.log(error);
                                setBEnviar(true);
                                setEditar(false);
                                setError(true);
                                setMensajeR(4);
                                setMensajeError(MensajeError(error));
                                setDisableBL(true);

                            });

                        setActualizar(false);
                    }
                } else {

                    setMensajeR(4);
                    setMensajeError(MensajeError(campos_vacios, 1));
                    return;
                }

            } else{

                setMensajeR(5);
                return;
            }
                
        } else if (e.target.innerText == 'Editar') {

            setBEnviar(true);
            setEditar(false);

            if (error)
                setActualizar(false);
            else
                setActualizar(true);
        }
    }

    const handleReset = () => {

        //console.log('Eliminar');

        axios
            .delete(`${baseURL}/${data.id}`)
            .then(() => {

                setMensajeR(3);
                setPost(null);

            }).catch(error=>{

                //console.log(error);
                setMensajeR(4);
                setMensajeError(error);
            });

        setBEnviar(true);
        setEditar(false);
        setDisableBL(true);
        document.getElementById('formObs').reset();
    }

    return (
        <div id='contentO'>
            <div className='div-standar'>
                <h2>{titulo.titulo}</h2>
                <form id='formObs' className='form control' onSubmit={handleSubmit} autoComplete="off">
                    
                        {
                            campos.map((elem, index) => {

                                if (elem.tipo === 'texto') return <InputTypeTextO key={index+100}
                                    dataType={elem.campo}
                                    data={data}
                                    setData={setData}
                                    editar={editar} 
                                    dataObserver={dataObserver}
                                    />
                                
                                else if (elem.tipo === 'espacio') return <><hr /><br /></>

                                else if (elem.tipo === 'titulo1') return <Label1 key={index} title={elem.campo+1000} />
    
                                else if (elem.tipo === 'titulo2') return <Label2 key={index} title={elem.campo+1000} />
                            })
                        }
                        <div className='row mx-auto'>
                                    {
                                        bEnviar ?
                                            <div className='col-12'>
                                            <button id='bsubmit' type="submit"
                                                className="btn btn-primary"
                                                onClick={handleSubmit}>
                                                Enviar
                                            </button>
                                            </div>
                                            :
                                            <>
                                                <div className='col-6 mx-auto'>
                                                <button type="button"
                                                    className="btn btn-primary me-1"
                                                    onClick={handleSubmit}>
                                                    Editar
                                                </button>
                                                <button type="button"
                                                    className="btn btn-danger "
                                                    onClick={handleReset}>
                                                    Eliminar
                                                </button>
                                                </div>
                                            </>
                                    }
                        </div>
                    
                    <MensajeRespuesta mensajeR={mensajeR} 
                                      mensajeError={mensajeError}/>
                </form>
            </div>
        </div>
    )
}