export default [
    {
      title1:'Hora'
    },
    {
      title1: 'Especie'
    },
    {
      title1: 'Observada / Capturada'
    },
    {
      title1: 'Actividad'
    },
    {
      title1: 'Talla Estimada'
    },
    {
      title1: "Morfometría LSCC Largo curvo estándar del carapacho"
    },
    {
      title1: "Morfometría ACC Ancho curvo del carapacho"
    },
    {
      title1: "Sexo"
    },
    {
      title1: "Número marca o tags"
    },
    {
      title1: "Estado físico de la tortuga o individuo capturado o daño presente"
    },
    {
      title1: "Observaciones"
    },
  ]
