import React, { useEffect, useState } from 'react'
import '../style/ShowTable.css'
import { BsPlusSquareFill, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import { MensajeRespuesta } from '../../Sistema/components/MensajeRespuesta';
import axios from "axios";
import { Elementos } from './Elementos';

export const ShowTable = ({ headers, setAccion, registros,
                            setVisible, setRegistroE, sObs,
                            setRegistros, mensajeR, setMensajeR,
                            mensajeA, setMensajeA,mensajeError,
                            tabla, setEnviado,data, setData}) => {

    const [eliminar, setEliminar] = useState(false);

    //var baseURL = "http://localhost:8080/sitioObservado";
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/sitioObservado";

    useEffect(() => {

        if (eliminar==true) {

            axios.get(`${baseURL}/${sObs.id}/${tabla}`)
                .then((response) => {

                    //console.log('GET registros luego de eliminar')
                    //console.log(response);
                    setRegistros(response.data);
                    setVisible(false);
                    setMensajeA(1);
                    setEliminar(false);
                })
                .catch((error) => {
                    //console.log(error);
                    setMensajeA(2);
                });
        }

    }, [eliminar])

    const handleAnadir = () => {

        //console.log("Anadir")
        setAccion('Enviar');
        setVisible(true);
        setRegistroE({});
        setMensajeA(0);
        setEnviado(0);
        setData({});

    };

    const handleEditar = (elem) => {

        //console.log("Editar")
        //console.log(elem);
        setAccion('Actualizar');
        setVisible(true);
        setRegistroE(elem);
        setData(elem);
        setEnviado(0);

    };

    const handleEliminar = (id) => {
        //console.log("Eliminar");
        axios
            .delete(`${baseURL}/${sObs.id}/${tabla}/${id}`)
            .then((response) => {

                //console.log(response.data);
                setEliminar(true);
                setMensajeR(3);
                setEnviado(0);

            }).catch(error => {

                //console.log(error);
                setMensajeR(4);
                setMensajeA(2);

            });
    };

    return (
        <div>
            <br />
            <div className='div-mensajes'>
                {
                    mensajeA === 1?
                        <div className="alert alert-info" role="alert">
                            Tabla actualizada
                        </div>
                        :
                        <></>
                }
                {
                    mensajeA === 2?
                        <div className="alert alert-warning" role="alert">
                            Tabla NO actualizada
                        </div>
                        :
                        <></>
                }
            </div>
            <div className='div-mensajes'>
                <MensajeRespuesta mensajeR={mensajeR} mensajeError={mensajeError}/>
            </div>
            <div className='show-scroll'>
                <table className='table'>
                    <thead>
                        <tr>
                            <td colSpan="2"> <button id='an' onClick={handleAnadir} type="button" className="btn btn-primary">Añadir Fila <BsPlusSquareFill /></button> </td>
                            {
                                headers.map((elem, index) => <td key={index}>{elem}</td>)
                            }
                        </tr>
                    </thead>
                    <tbody className='table-group-divider'>

                        {
                            registros.length != 0 ?
                                registros.map((elem, index) => {
                                    return (
                                        
                                    <tr key={index}>
                                        <td><button id='ed' onClick={() => handleEditar(elem)} type="button" className="btn btn-primary">Editar <BsFillPencilFill /> </button></td>
                                        <td><button id='el' onClick={() => handleEliminar(elem.id)} type="button" className="btn btn-primary">Eliminar <BsFillTrashFill /> </button></td>
                                        <Elementos elem={elem} tabla={tabla}/>
                                    </tr>
    
                                    );
                                
                                })
                                :
                                <tr><td colSpan={2}>Sin datos</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <br />
        </div>
    )
}