import React from 'react'
import titulos from '../config/T3HeadersFields'
import '../../MoniTablas/MonNidEEclo/style/MonNidEEclo.css'

export const MonNidEEclo = ({tabla3}) => {
    return (
        <div>
            {
                tabla3 !=null && Object.entries(tabla3).length != 0 ?
                    <div className='show-scroll'>
                        <table id='mnee-table' className="table table-bordered">
                            <thead>
                                <tr>
                                    {
                                        titulos.map((elem,index) => {

                                            return (
                                                elem.titles2 != null || elem.titles2 != undefined ?
                                                    elem.titles2.split(',').map(elem2 => <td key={index} >{elem2}</td>)
                                                    :
                                                    <td key={index+'h2fmnee'} >{elem.title1}</td>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tabla3.map((elem,index)=>{

                                    return(
                                        <tr>
                                            <td key={elem.id+'c21mnee'} >{elem.num_nido}</td>
                                            <td key={elem.id+'c22mnee'} >{elem.fecha_ec_ex}</td>
                                            <td key={elem.id+'c23mnee'} >{elem.especie}</td>
                                            <td key={elem.id+'c24mnee'} >{elem.num_crias_vivas}</td>
                                            <td key={elem.id+'c25mnee'} >{elem.num_huevos_eclo}</td>
                                            <td key={elem.id+'c26mnee'} >{elem.num_crias_muertas}</td>
                                            <td key={elem.id+'c27mnee'} >{elem.num_huevos_sin_des_inf}</td>
                                            <td key={elem.id+'c28mnee'} >{elem.huevos_dep}</td>
                                            <td key={elem.id+'c29mnee'} >{elem.total_huevos_no_eclo}</td>
                                            {/*<td key={elem.id} >{elem.huevos_infertiles}</td>*/}
                                            <td key={elem.id+'c210mnee'} >{elem.num_total_huevos}</td>
                                            <td key={elem.id+'c211mnee'} >{elem.crias_sup_corral}</td>
                                            <td key={elem.id+' 212mnee'} >{elem.riesgo_perdida}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <p>Sin Datos</p>
            }
            <br />
    </div >
  )
}
