export default [  
  {
    tipo: "titulo1",
    campo: "Número del nido"
  },
  {
    tipo: "texto",
    campo: "Num_nido"
  },
  {
    tipo: "titulo1",
    campo: "Fecha de eclosión o exhumación"
  },
  {
    tipo: "texto",
    campo: "Fecha_ec_ex"
  },
  {
    tipo: "espacio",
    campo: "espacio"
  },
  {
    tipo:'ayuda',
    mensaje:'Especie: tortuga verde o blanca (Chelonia mydas), '+
            'el carey (Eretmochelys imbricata), caguama o caguamo (Caretta caretta), '+
            'tortuga golfina (Lepidochelys olivacea), el tinglar, (Dermochelys coriacea)'
  },
  {
    tipo: "texto",
    campo: "Especie"
  },
  {
    tipo: "titulo1",
    campo: "Número de crías vivas o Neonatos vivos"
  },
  {
    tipo: "texto",
    campo: "Num_crias_vivas"
  },
  {
    tipo: "titulo1",
    campo: "Número de huevos que han eclosionado (cascarones)"
  },
  {
    tipo: "texto",
    campo: "Num_huevos_eclo"
  },
  {
    tipo: "titulo1",
    campo: "Número de crías muertas o Neonatos muertos"
  },
  {
    tipo: "texto",
    campo: "Num_crias_muertas"
  },
  {
    tipo: "titulo1",
    campo: "Número de huevos sin desarrollo aparente (infértiles)"
  },
  {
    tipo: "texto",
    campo: "Num_huevos_sin_des_inf"
  },
  {
    tipo: "titulo1",
    campo: "Huevos Depredados"
  },
  {
    tipo: "texto",
    campo: "Huevos_dep"
  },
  {
    tipo: "titulo1",
    campo: "Total de Huevos no eclosionados"
  },
  {
    tipo: "texto",
    campo: "Total_huevos_no_eclo"
  },
  /*{
    tipo: "titulo1",
    campo: "Huevos Infértiles"
  },
  {
    tipo: "texto",
    campo: "Huevos_Infertiles"
  },*/
  {
    tipo: "titulo1",
    campo: "Número Total de Huevos"
  },
  {
    tipo: "texto",
    campo: "Num_total_huevos"
  },
  {
    tipo: "titulo1",
    campo: "Crías en superficie (corral)"
  },
  {
    tipo: "texto",
    campo: "Crias_sup_corral"
  },
  {
    tipo: "titulo1",
    campo: "Riesgos en nido o Pérdida del nido"
  },
  {
    tipo:'ayuda',
    mensaje:'Inundación, saqueo, depredación, erosión, mareas extraordinarias, recale de sargazo'
  },
  {
    tipo: "texto",
    campo: "Riesgo_perdida"
  },
  {
    tipo: "espacio",
    campo: "espacio"
  }
]