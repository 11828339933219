import React from 'react'
import titulos from '../config/T2HeadersFields'
import '../../MoniTablas/MonDiurDetNid/style/MonDiurDetNid.css'

export const MonDiurDetNid = ({tabla2}) => {
    return (
        <div>
            {
                tabla2 !=null && Object.entries(tabla2).length != 0 ?
                    <div className='show-scroll'>
                        <table id='mddn-table' className="table table-bordered">
                            <thead>
                                <tr>
                                    {
                                        titulos.map((elem,index) => {

                                            return (
                                                
                                                <td key={index + 'hf2mddn'} >{elem.title1}</td>
                                            );
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tabla2.map((elem,index)=>{

                                    return(
                                        <tr>
                                            <td key={elem.id+index+'c11mddn'} >{elem.latitud}</td>
                                            <td key={elem.id+index+'c12mddn'} >{elem.longitud}</td>
                                            <td key={elem.id+index+'c13mddn'} >{elem.especie}</td>
                                            <td key={elem.id+index+'c14mddn'} >{elem.num_nido}</td>
                                            <td key={elem.id+index+'c15mddn'} >{elem.nido_perfil}</td>
                                            <td key={elem.id+index+'c16mddn'} >{elem.condicion_nido}</td>
                                            <td key={elem.id+index+'c17mddn'} >{elem.num_huevos_nido}</td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    :
                    <p>Sin Datos</p>
            }
            <br />
    </div >
  )
}
