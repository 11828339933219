import React from 'react'
import { Link } from 'react-router-dom'
import '../style/ButtonLogin.css'

export const ButtonLogin = () => {
    return (
        <>
        <div id='button-login' className='btn mt-2'>
                <Link to='/login'>
                    <div className='fs-4'>
                        <i class="fa-solid fa-right-to-bracket me-2"></i>
                        Login
                    </div>
                </Link>
        </div>
        </>
    )
}
