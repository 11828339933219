import React, { useEffect, useState } from 'react'
import '../style/TableUsuario.css'
import { Link } from 'react-router-dom'
import axios from "axios";

export const TableUsuario = ({usuarios,setEliminar,
                              setUsuarioE,setRefresh,
                              search,setSearch,
                              setBSearch,setResultados}) => {

    //var baseURL = "http://localhost:8080/usuarios";
    var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/usuarios";
    
    const handleEliminar = (id) => {
        //console.log("Eliminar");
        axios
            .delete(`${baseURL}/${id}`)
            .then((response) => {

                //console.log(response);
                setEliminar(response);

            }).catch(error => {

                //console.log(error);

            });
    };

    const handleEditar=(usuario)=>{
        //console.log('Editar');
        setUsuarioE(usuario);
    };

    const Refrescar=()=> setRefresh(true);

    const handleChange=(e)=>{

        const { name, value } = e.target;
        
        setSearch({
            ...search,
            [name]: value
        });

        //console.log(search);
    };

    const handleSearch=(e)=>{
        e.preventDefault();
        setBSearch(true);
        setResultados(2);
    };

    return (
        <div className='container p-4'>
            <div className='card bg-success bg-opacity-75 p-3'>
                <div className='card-title'>
                    <h3>Usuarios Registrados</h3>
                </div>
                <div className='card-body p-2'>
                    <div className='row mt-2 mb-2'>
                        <div className='col-6'>
                            <button className='btn btn-info text-white' onClick={Refrescar}>
                                <i className="fa-solid fa-arrows-rotate me-2"></i>
                                Mostrar Todos
                            </button>
                        </div>

                        <div className='col-6'>
                            <button className='btn btn-info text-white'>
                                <i className="fa-solid fa-rotate-left me-2"></i>
                                <Link style={{ color: "white" }} to='/'>
                                    Volver
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className='row mt-4 mb-2 mx-auto p-2 '>
                        <form id='formSearch' onSubmit={handleSearch}>
                            <div className='row d-flex'>
                                <div className='col-5'>
                                    <input type='text' className='form-control' id='bnombre' name='bnombre'
                                        placeholder='Buscar por nombre' onChange={handleChange}/>
                                </div>
                                <div className='col-5'>
                                    <input type='email' className='form-control' id='bemail' name='bemail'
                                        placeholder='Buscar por email' onChange={handleChange}/>
                                </div>
                                <div className='col-2'>
                                    <button className='btn btn-primary' onClick={handleSearch}>
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='card-body rounded p-2 mt-2' id='tableUsers' >
                    <table className='table  '>
                        <thead className=''>
                            <th>Editar</th>
                            <th>Eliminar</th>
                            <th>Nombre</th>
                            <th>Edad</th>
                            <th>Profesión</th>
                            <th>Email</th>
                            <th>Usuario</th>
                            <th>Tipo de Usuario</th>
                        </thead>
                        <tbody className='text-white'>
                            {   
                                usuarios.map(elem => {

                                    return (
                                        <tr key={elem.id}>
                                            <td>
                                                <div className='col-3'>
                                                    <button className='btn btn-info text-white' 
                                                            onClick={() => handleEditar(elem)}>
                                                        <i className="fa-solid fa-pen"></i>
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <button className='btn btn-danger text-white'
                                                    onClick={() => handleEliminar(elem.id)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                            <td>
                                                {elem.nombre}
                                            </td>
                                            <td>
                                                {elem.edad}
                                            </td>
                                            <td>
                                                {elem.profesion}
                                            </td>
                                            <td>
                                                {elem.email}
                                            </td>
                                            <td>
                                                {elem.usuario}
                                            </td>
                                            <td>
                                                {elem.tipoU}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
