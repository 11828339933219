import React, { useEffect, useState } from 'react'
import '../style/DeployerO.css'

export const DeployerO = ({ titulo, contentDeployer,disableO,busquedaO,setBusquedaOC,setDisableO}) => {

    const [deploy, setDeploy] = useState(false);
    const [cargar,setCargar]=useState(false);
    const handleDep = () => setDeploy(!deploy);

    if (cargar == false) {
        if (busquedaO != null && busquedaO != undefined && Object.entries(busquedaO).length != 0) {

            setDeploy(true);
            setCargar(true);
        }
    }

    useEffect(() => {

        if(deploy){
            document.getElementsByClassName("form-control").item(0).focus();
            setBusquedaOC(busquedaO);
            setDisableO(true);
        }
  
    }, [deploy])

    return (
        <div>
            <br/>
            <div className="d-grid gap-2 col-10 mx-auto">
                <button id='p_button' className='btn btn-primary' onClick={handleDep} disabled={disableO}>{titulo}</button>
            </div>
            {
                deploy ?
                    contentDeployer
                    : <></>
            }
            <br/>
        </div>
    )
}

