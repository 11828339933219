export const MensajeError2 = (error_message,infoJSON) => {

    var mensaje = '';
    var campos = '';

    if (error_message.code.includes('ERR_NETWORK')) {

        mensaje = "Error en los servicios";

    } else if (error_message.code.includes('ERR_BAD_RESPONSE')) {

        if (error_message.response.data.message.includes('Validation failed')) {

            error_message.response.data.message.split(',').map((elem) => {

                if (elem.includes('propertyPath=')) {

                    if (elem.substring(14, (elem.length)) === 'fecha')
                        campos += elem.substring(14, (elem.length)) + '(formato o vacio) , ';
                    else
                        campos += elem.substring(14, (elem.length)) + ' , ';

                }
            });

            if (infoJSON.anp == 'SI') {

                if (infoJSON.nombre_anp == null || infoJSON.nombre_anp == '')
                    campos += 'nombre_anp , ';
            }

            mensaje = 'Error ingresar campos faltantes: ' + campos;

        } else if (error_message.response.data.message.includes('could not execute statement')) {

            mensaje = 'Error posibles campos repetidos en otro registro: ----';

        } else if (error_message.response.data.message.includes('Could not commit JPA transaction')){
                   mensaje='Error fecha (formato o vacio) , '
        }else
            mensaje = 'Error';

    }

    return mensaje;
}