const VerificarInfo2 = (infoJSON) => {

    let caracteres = '';
    let aux='';
    var data={};

    //console.log("verificando")
    //console.log(Object.values(infoJSON));

    Object.values(infoJSON).forEach((elem, index) => {
    
        if (typeof Object.values(infoJSON)[index] == 'string' &&
            Object.keys(infoJSON)[index] != 'fecha' &&
            Object.values(infoJSON)[index] != null &&
            Object.keys(infoJSON)[index] != 'prof_valor' &&
            Object.keys(infoJSON)[index] != 'vistransver_valor' &&
            Object.keys(infoJSON)[index] != 'tempa_valor' &&
            Object.keys(infoJSON)[index] != 'edad' &&
            Object.keys(infoJSON)[index] != 'num_nido' &&
            Object.keys(infoJSON)[index] != 'num_huevos' &&
            Object.keys(infoJSON)[index] !='cgpsinicio_lat' &&
            Object.keys(infoJSON)[index] !='cgpsinicio_long' &&
            Object.keys(infoJSON)[index] !='cgpsfin_lat' &&
            Object.keys(infoJSON)[index] !='cgpsfin_long' ) {
                
            caracteres = elem
                .split('')
                .filter((elem) => elem !== '.' && elem !== ',' && elem !== ';' &&
                                  elem !== '=' && elem !== '/' && elem !== String.fromCharCode(47) &&
                                  elem !== '*' && elem !== '%' && elem !== String.fromCharCode(39)) //47 es '\' y 39 es '

            aux = caracteres.join('');

            data[Object.keys(infoJSON)[index]] = aux;

        } else
            data[Object.keys(infoJSON)[index]]=Object.values(infoJSON)[index];
    })

    return data;
}

export default VerificarInfo2;