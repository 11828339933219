import React, { useEffect, useState } from 'react'
import '../style/DeployerT.css'


export const DeployerT = ({ titulo, contentDeployer, disableBT,busquedaSO,setMoniTablas}) => {

    const [deploy, setDeploy] = useState(false);
    const [cargar,setCargar]=useState(false);

    const handleDep = () => setDeploy(!deploy);

    if (cargar == false) {
        if (busquedaSO != null && busquedaSO != undefined && Object.entries(busquedaSO).length != 0) {

            setDeploy(true);
            setCargar(true);
            disableBT=true;
        }
    }

    useEffect(() => {

        var aux=[];
        if(deploy){

            if(busquedaSO.mndha!=null || busquedaSO.mndha!=undefined )
                aux[0]=busquedaSO.mndha;
            else
                aux[0]=[];

            if(busquedaSO.mndha!=null || busquedaSO.mndha!=undefined )
                aux[1]=busquedaSO.mddn;
            else
                aux[1]=[];

            if(busquedaSO.mnee!=null || busquedaSO.mnee!=undefined )
                aux[2]=busquedaSO.mnee;
            else
                aux[2]=[];

            if(busquedaSO.mam!=null || busquedaSO.mam!=undefined )
                aux[3]=busquedaSO.mam;
            else
                aux[3]=[];

            setMoniTablas(aux);    

        }

        disableBT=true;

    }, [deploy])

    return (
        <div>
            <br/>
            {
                disableBT ?
                    <div className='div-buttond'>
                        <button className='p_buttond' onClick={handleDep} disabled={disableBT}>{titulo}</button>
                    </div>
                    :
                    <div className='div-button'>
                        <button className='p_button' onClick={handleDep} disabled={disableBT}>{titulo}</button>
                    </div>
            }
            {
                deploy ?
                    contentDeployer
                    : <></>
            }
            <br/>
        </div>
    )
}

