export const CapturaVaciosF = (infoJSON) => {

    let campos_vacios = '';
    let aux = '';
    let aux2='';

    Object.values(infoJSON).map((elem, index) => {

        if (Object.keys(infoJSON)[index] != 'id' && Object.keys(infoJSON)[index]  != 'sob') {

            if (elem != null && (elem == '' || elem == 0 || elem == '0')) {

                aux += Object.keys(infoJSON)[index] + ' , ';
            }
        }

        if (Object.keys(infoJSON)[index] == 'edad') {

            if (typeof elem == 'string') 
                elem= parseInt(elem);
            
            if (elem <18 || elem > 80)
                aux2 = 'Error formato edad igual o mayor a 18 y menor a 80 ';
        }
    });

    if (aux == '' && aux2 =='')
        campos_vacios = '';

    else if(aux!='' && aux2!='')
        campos_vacios = aux2 + 'o' +' Error campos vacios: ' + aux;

    else if(aux!='' && aux2=='')
        campos_vacios ='Error campos vacios: ' + aux;
    
    else if(aux=='' && aux2!='')
        campos_vacios = aux2;

    return campos_vacios;
}