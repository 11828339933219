export default [

    {
      tipo: "texto",
      campo: "Nombre"
    },
    {
      tipo: "texto",
      campo: "Email"
    },
    {
      tipo: "texto",
      campo: "Edad"
    },
    {
      tipo: "texto",
      campo: "Pais"
    },
    {
      tipo: "texto",
      campo: "Profesion"
    },
    {
      tipo: "titulo1",
      campo: "Institución / Organización"
    },
    {
      tipo: "texto",
      campo: "Institucion_Organizacion"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    }

  ]