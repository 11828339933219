import React from 'react'

export const Elementos = ({ elem, tabla }) => {

    return (
        <>
            {
                tabla === 'mndhas' ?
                    <>
                        <td>{elem.hora}</td>
                        <td>{elem.actividad}</td>
                        <td>{elem.latitud}</td>
                        <td>{elem.longitud}</td>
                        <td>{elem.num_nido}</td>
                        <td>{elem.especie}</td>
                        <td>{elem.morfometria_lscc}</td>
                        <td>{elem.morfometria_acc}</td>
                        <td>{elem.estado_fisico}</td>
                        <td>{elem.ancho_cabeza}</td>
                        {/*<td>{elem.zona_balizas}</td>*/}
                        <td>{elem.num_huevos}</td>
                        <td>{elem.nido_perfil}</td>
                        <td>{elem.temp_nido}</td>
                        <td>{elem.prof_nido}</td>
                        <td>{elem.marca_tags}</td>
                        <td>{elem.tortuga_gps}</td>
                        <td>{elem.observaciones}</td>       
                    </>
                    :
                    <>
                    </>
            }
            {
                tabla === 'mddn' ?
                    <>
                        <td>{elem.latitud}</td>
                        <td>{elem.longitud}</td>
                        <td>{elem.especie}</td>
                        <td>{elem.num_nido}</td>     
                        <td>{elem.nido_perfil}</td>
                        <td>{elem.condicion_nido}</td>
                        <td>{elem.num_huevos_nido}</td>
                    </>
                    :
                    <>
                    </>

            }
            {
                tabla === 'mnee' ?
                    <>
                        <td>{elem.num_nido}</td>
                        <td>{elem.fecha_ec_ex}</td>
                        <td>{elem.especie}</td>     
                        <td>{elem.num_crias_vivas}</td>
                        <td>{elem.num_huevos_eclo}</td>
                        <td>{elem.num_crias_muertas}</td>
                        <td>{elem.num_huevos_sin_des_inf}</td>
                        <td>{elem.huevos_dep}</td>
                        <td>{elem.total_huevos_no_eclo}</td>
                        {/*<td>{elem.huevos_infertiles}</td>*/}
                        <td>{elem.num_total_huevos}</td>
                        <td>{elem.crias_sup_corral}</td>
                        <td>{elem.riesgo_perdida}</td>
                    </>
                    :
                    <>
                    </>
            }
            {
                tabla === 'mam' ?
                    <>
                        <td>{elem.hora}</td>
                        <td>{elem.especie}</td>
                        <td>{elem.observada_capturada}</td>
                        <td>{elem.actividad}</td>
                        <td>{elem.talla_estimada}</td>
                        <td>{elem.morfometria_lscc}</td>
                        <td>{elem.morfometria_acc}</td>
                        <td>{elem.sexo}</td>
                        <td>{elem.num_marcas_tags}</td>
                        <td>{elem.estado_fisico}</td>
                        <td>{elem.observaciones}</td>
                    </>
                    :
                    <>
                    </>
            }
        </>
    )
}
