import React, { useEffect, useState } from 'react'
import { FormTable } from '../../components/FormTable'
import { ShowTable } from '../../components/ShowTable';
import tableFields4 from '../config/TableFields4';
import headersTable4 from '../config/TableHeaders4';
import '../../style/ContentDeployer.css'

export const ContentDeployerC4 = ({sObs,moniTablas,setEnviado,numtabla}) => {

    const [visible,setVisible] =useState(false);
    const [accion, setAccion] = useState('');
    const [registros,setRegistros]=useState([]);
    const [registroE,setRegistroE]=useState({});
    const [mensajeR,setMensajeR]=useState(0);
    const [mensajeA,setMensajeA]=useState(0);
    const [mensajeError, setMensajeError] = useState('');
    const [data, setData] = useState({});
    const [cargar,setCargar]=useState(false);

    if (cargar == false) {
        if (moniTablas[3] != null && moniTablas[3] != undefined && moniTablas[3].length != 0) {

            setRegistros(moniTablas[3]);
            setCargar(true);
        }
    }

    useEffect(() => {

        //console.log("U Data")
        //console.log(data);

    }, [data]);

    useEffect(()=>{

        if (visible){
            document.getElementsByClassName("form-control").namedItem("hora").focus();

            /*
                especie:'',
                observada_capturada:'',
                actividad:'',
                talla_estimada:'',
                morfometria_lscc:0,
                morfometria_acc:0,
                sexo:'',
                num_marcas_tags:'',
                estado_fisico:'',
                observaciones:'',
            */
        }

    },[visible])

    return (
            <div id='content'>
                <br/>
                <ShowTable headers={headersTable4} 
                    setAccion={setAccion} 
                    setVisible={setVisible}
                    setRegistroE={setRegistroE}
                    registros={registros} 
                    sObs={sObs} 
                    setRegistros={setRegistros}
                    mensajeR={mensajeR}
                    setMensajeR={setMensajeR}
                    mensajeA={mensajeA}
                    setMensajeA={setMensajeA}
                    mensajeError={mensajeError}
                    tabla={'mam'}
                    setEnviado={setEnviado}
                    data={data}
                    setData={setData}              
                    />
                {
                    visible? <FormTable titulo={"Características monitoreadas"} 
                                        campos={tableFields4} 
                                        accion={accion}               
                                        sObs={sObs} 
                                        setRegistros={setRegistros}
                                        setVisible={setVisible}
                                        visible={visible}
                                        registroE={registroE}
                                        mensajeR={mensajeR}
                                        setMensajeR={setMensajeR}
                                        setMensajeA={setMensajeA}
                                        mensajeError={mensajeError}
                                        setMensajeError={setMensajeError}
                                        tabla={'mam'}
                                        tipotabla={4}
                                        setEnviado={setEnviado}
                                        data={data}
                                        setData={setData}    
                                       />
                        :
                        <></>
                }
                <br/>
            </div>
    )
}