export default [
    {
      title1: 'Fecha'
    },
    {
      title1: 'Nombre del sitio o playa'
    },
    {
      title1: 'Coordenadas GPS',
      titles2:'Inicio Latitud (N/+),Inicio Longitud (W/-), Fin Latitud (N/+), Fin Longitud (W/-)'
    },
    {
      title1:'País'
    },
    {
      title1: 'Estado / Provincia'
    },
    /*{
      title1: 'Población Cercana'
    },*/
    {
      title1: 'Área natural protegida',
      titles2:'Existe,Nombre'
    },    
    {
      title1: 'Profundidad',
      titles2:'Valor,Unidad,Medida con'
    },
    {
      title1: 'Visibilidad / Transparencia - Vertical',
      titles2: 'Valor,Unidad,Medida con'
    },
    {
        title1:'Temperatura del Agua',
        titles2:'Valor,Unidad,Medida en,Medida con'
    },
    {
      title1: "Técnica de monitoreo"
    },
    {
      title1: "Tipo de fondo"
    }    
  ]
