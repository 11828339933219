import React, { useEffect, useState } from 'react'
import { FormUsuario } from '../components/FormUsuario'
import { TableUsuario } from '../components/TableUsuario'
import { useForm } from '../../Hooks/useForm'
import axios from "axios";

const initialForm = {

  nombre: '',
  edad: '',
  profesion: '',
  email: '',
  usuario: '',
  clave: '',
  cclave: '',
  tipoU: ''

};

const validateForm=(form)=>{

  let errors={};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexUser = /^[0-9a-zA-Z]+$/;
  let regexPW = /^[0-9a-zA-Z]+$/;

  if(!form.nombre.trim())
      errors.nombre="El campo nombre es requerido.";
  else{

      if(!regexName.test(form.nombre.trim()))
          errors.nombre='El campo nombre solo acepta letras';
  }

  if (typeof form.edad == 'number') {

    form.edad=(form.edad).toString();

    if (!form.edad.trim())
      errors.edad = "El campo edad es requerido.";
    else {

      if (parseInt(form.edad) < 18 || parseInt(form.edad) > 90)
        errors.edad = 'La edad debe ser entre 18 y 90 años'
    }
  } else {

    if (!form.edad.trim())
      errors.edad = "El campo edad es requerido.";
    else {

      if (parseInt(form.edad) < 18 || parseInt(form.edad) > 90)
        errors.edad = 'La edad debe ser entre 18 y 90 años'
    }

  }


  if(!form.profesion.trim())
      errors.profesion="El campo profesión es requerido.";
  

  if(!form.email.trim())
      errors.email="El campo email es requerido.";
  else{

      if(!regexEmail.test(form.email.trim()))
          errors.email="El email no es valido.";
  }
  
  if(!form.usuario.trim()){
      errors.usuario="El campo usuario es requerido.";
  }
  else{

    if(form.usuario.length<8){
      errors.usuario="El usuario debe tener almenos 8 caracteres";

    }else{

      if(!regexUser.test(form.usuario.trim()))
        errors.usuario='El campo usuario solo puedo contener letras y numeros';

    }
  }
  
  
  if(!form.clave.trim())
      errors.clave="La contraseña es requerida.";
  else{

      if(form.clave.length<8){
          errors.clave="La contraseña debe tener almenos 8 caracteres";
      } else {

        if (!regexPW.test(form.clave.trim()))
          errors.clave = 'El campo contraseña solo puedo contener letras y numeros';
      }

  }

  

  if(!form.cclave.trim())
      errors.cclave="El campo confirmar contraseña es requerido.";
  else{

      if(form.clave!=form.cclave)
          errors.cclave="La contraseña y su confirmación no coinciden";
  }

  if(!form.tipoU.trim())
      errors.tipoU="Seleccionar el tipo de usuario es requerido";
  
  return errors;
};


export const GestionUsuarios = () => {

  const [usuarios, setUsuarios] = useState([]);
  const [post, setPost] = useState({});
  const [put, setPut] = useState({});
  const [eliminar,setEliminar]=useState({});
  const [usuarioE, setUsuarioE] = useState({});
  const [actualizar,setActualizar]=useState(false);
  const [refresh,setRefresh]=useState(false);
  const [search, setSearch] = useState({});
  const [bsearch,setBSearch]=useState(false);
  const [resultados, setResultados] = useState(0);
  const [errorBD,setErrorBD]=useState({});
  const [mensajeEBD,setMensajeEBD]=useState('');

  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit
} = useForm(initialForm, validateForm,setPost,setPut,usuarioE,setUsuarioE,actualizar,setActualizar,setErrorBD);

  //var baseURL = "http://localhost:8080/usuarios";
  var baseURL = "https://monitortuga.monitoreomarinocosterord.com:8442/usuarios";

  useEffect(() => {

    if (Object.entries(errorBD).length == 0) {
      setMensajeEBD('');

      if (Object.entries(post) != 0 || Object.entries(put) != 0 || Object.entries(eliminar) != 0) {
        //console.log('UE PPE');

        if (resultados == 1) {

          axios.get(`${baseURL}`)
            .then((response) => {

              //console.log('GET lista de registros')
              setUsuarios(response.data);
              document.getElementById('formUser').reset();
              setPost({});
              setPut({});
              setEliminar({});

            }).catch((error) => {/*console.log(error)*/});

        } else if (resultados == 2) {

          if (search.bnombre != '' && search.bnombre != null) {

            axios.get(`${baseURL}/n/${search.bnombre}`)
              .then((response) => {

                //console.log('BNombreA lista de registros')
                setUsuarios(response.data);
                document.getElementById('formUser').reset();
                document.getElementById('formSearch').reset();
                setSearch({});
                setBSearch(false);
                setPost({});
                setPut({});
                setEliminar({});

              })
              .catch((error) => {/*console.log(error)*/});

          }

          if (search.bemail != '' && search.bemail != null) {

            axios.get(`${baseURL}/e/${search.bemail}`)
              .then((response) => {

                //console.log('BEmailA lista de registros')
                setUsuarios(response.data);
                document.getElementById('formSearch').reset();
                document.getElementById('formUser').reset();
                setSearch({});
                setBSearch(false);
                setPost({});
                setPut({});
                setEliminar({});

              })
              .catch((error) => {/*console.log(error)*/});
          }
        }
      }

    }else {

      if(errorBD.code=='ERR_BAD_RESPONSE'){

        setMensajeEBD('Usuario repetido, cambiar usuario');
      }

    }

  }, [post, put, eliminar, errorBD])

  useEffect(() => {

    if (refresh == true ) {

      axios.get(`${baseURL}`)
        .then((response) => {

          //console.log('Resfresh lista de registros')
          setUsuarios(response.data);
          document.getElementById('formUser').reset();
          document.getElementById('formSearch').reset();
          setSearch({});
          setRefresh(false);
          setResultados(1);

        })
        .catch((error) => {/*console.log(error)*/});
    }

  }, [refresh])
  
 useEffect(() => {

    if (bsearch == true) {

      if (Object.entries(search).length != 0) {

        if (search.bnombre != '' && search.bnombre != null) {

          axios.get(`${baseURL}/n/${search.bnombre}`)
            .then((response) => {

              //console.log('BNombre lista de registros')
              setUsuarios(response.data);
              setBSearch(false);

            })
            .catch((error) => {/*console.log(error)*/});

        }

        if (search.bemail != '' && search.bemail != null) {

          axios.get(`${baseURL}/e/${search.bemail}`)
            .then((response) => {

              //console.log('BEmail lista de registros')
              setUsuarios(response.data);
              setBSearch(false);

            })
            .catch((error) => {/*console.log(error)*/});
        }
      }
    }

  }, [bsearch])
  
  return (
    <div className='container'>
      <div className='row mx-auto'>
        <h2>Gestionar Usuarios</h2>
      </div>
      <div className='row'>
        <div className='col-7 mx-auto'>
          <TableUsuario usuarios={usuarios} 
                        setEliminar={setEliminar} 
                        setUsuarioE={setUsuarioE}
                        setRefresh={setRefresh}
                        search={search}
                        setSearch={setSearch}
                        setBSearch={setBSearch}
                        setResultados={setResultados}/>
        </div>
        <div className='col-5 mx-auto'>
          <FormUsuario form={form} errors={errors} 
                       handleChange={handleChange}  
                       handleBlur={handleBlur} 
                       handleSubmit={handleSubmit}
                       usuarioE={usuarioE} 
                       actualizar={actualizar} 
                       setActualizar={setActualizar}
                       mensajeEBD={mensajeEBD}/>
        </div>
      </div>
      <br/>
    </div>
  )
}
