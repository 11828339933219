export default [
    'Hora',	
    'Especie',
    'Observada / capturada',	
    'Actividad',	
    'Talla estimada',	
    'Morfometría LSCC Largo curvo estándar del carapacho',	
    'Morfometría ACC Ancho curvo del carapacho',	
    'Sexo',	
    'Número marca o tags',
    'Estado físico de la tortuga o individuo capturado o daño presente',
    'Observaciones'        
]