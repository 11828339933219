export const Sanitizar =(palabra_clave)=>{

    let palabra='';

    if (palabra_clave != '' || palabra_clave != null) {

        palabra = palabra_clave.split('')
                               .filter((elem) => elem !== '.' && elem !== ',' && elem !== ';' &&
                                                 elem !== '>' && elem !== '<' && elem !== '$' &&
                                                 elem !== '=' && elem !== String.fromCharCode(47) && 
                                                 elem !== String.fromCharCode(92) && elem !== '?' &&
                                                 elem !== '*' && elem !== '%' && elem !== String.fromCharCode(39));
        
        palabra=palabra.join('');

    } else {
        palabra = '';
    }

    return palabra.trim();
}