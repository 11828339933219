import React from 'react'
import { Link } from 'react-router-dom'
import '../style/ButtonHome.css'

export const ButtonHome = () => {
  return (
    <div id='button-home' className='btn btn-success mt-2 mb-4'>
        <Link to='/'>
        <i className="fa-solid fa-house-chimney"></i>&nbsp;
          Home
        </Link>
    </div>
  )
}
