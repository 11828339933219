import React, { useEffect, useState } from 'react'
import '../style/FormUsuario.css'
import { useForm } from '../../Hooks/useForm'

export const FormUsuario = ({form,errors,handleBlur, handleChange, handleSubmit, usuarioE, actualizar, setActualizar,mensajeEBD}) => {

useEffect(() => {

    if(Object.entries(usuarioE).length!=0 && actualizar!=true){
        //console.log('Usuario Editar')

        form.nombre=usuarioE.nombre;
        form.edad=usuarioE.edad;
        form.profesion=usuarioE.profesion;
        form.email=usuarioE.email;
        form.usuario=usuarioE.usuario;

        document.getElementById('nombre').value = usuarioE.nombre;
        document.getElementById('edad').value = usuarioE.edad;
        document.getElementById('profesion').value = usuarioE.profesion;
        document.getElementById('email').value = usuarioE.email;
        document.getElementById('usuario').value = usuarioE.usuario;
        if (usuarioE.tipoU == 'admin')
            document.getElementById('tipoU1').checked = true;

        else if (usuarioE.tipoU == 'user')
            document.getElementById('tipoU2').checked = true;
        
        form.tipoU=usuarioE.tipoU;

        setActualizar(true);
    }

}, )

const mostrarC=()=>{

    let clave=document.getElementById('clave');

    if (clave.getAttribute('type') == 'password')
        clave.setAttribute('type', 'text');
    
    else
        clave.setAttribute('type', 'password');
        
    //clave.focus();
}

const mostrarC2=()=>{

    let cclave=document.getElementById('cclave');

    if (cclave.getAttribute('type') == 'password')
        cclave.setAttribute('type', 'text');
    
    else
        cclave.setAttribute('type', 'password');
        
    //clave.focus();
}

  return (
    <div className='container p-4'>
        <div id='formUserC' className='card bg-success bg-opacity-75 p-4'>
            <div className='card-title'>
                <h3>Formulario Usuario</h3>
            </div>
            <div className='card-body'>
                <form id='formUser' onSubmit={handleSubmit}>
                    <div className='row mb-2'>
                        <div className='form-label' forhtml='nombre'>
                            Nombre 
                        </div>
                        <input type='text' className='form-control' id='nombre' name='nombre'
                                placeholder='Ingresar nombre' onChange={handleChange}
                                onBlur={handleBlur} 
                                required/>
                        {errors.nombre?<div className='alert alert-danger mt-2'>{errors.nombre}</div>:<></>}
                    </div>
                    <div className='row mb-2'>
                        <div className='form-label' forhtml='edad'>
                            Edad
                        </div>
                        <input type='number' className='form-control' id='edad' name='edad'
                                placeholder='Ingresar edad' onChange={handleChange}
                                onBlur={handleBlur} 
                                required/>
                        {errors.edad?<div className='alert alert-danger mt-2'>{errors.edad}</div>:<></>}
                    </div>
                    <div className='row mb-2'>
                        <div className='form-label' forhtml='edad'>
                            Profesión
                        </div>
                        <input type='text' className='form-control' id='profesion' name='profesion'
                                placeholder='Ingresar profesión' onChange={handleChange}
                                onBlur={handleBlur} 
                                required/>
                        {errors.profesion?<div className='alert alert-danger mt-2'>{errors.profesion}</div>:<></>}
                    </div>
                    <div className='row mb-2'>
                        <div className='form-label' forhtml='email'>
                            Email
                        </div>
                        <input type='email' className='form-control' id='email' name='email'
                                placeholder='Ingresar email' onChange={handleChange}
                                onBlur={handleBlur} 
                                required/>
                        {errors.email?<div className='alert alert-danger mt-2'>{errors.email}</div>:<></>}
                    </div>
                    <div className='row mb-4'>
                        <div className='form-label' forhtml='usuario'>
                            Usuario
                        </div>
                        <input type='text' className='form-control' id='usuario' name='usuario'
                                placeholder='Ingresar usuario' onChange={handleChange}
                                onBlur={handleBlur} 
                                required/>
                        {errors.usuario?<div className='alert alert-danger mt-2'>{errors.usuario}</div>:<></>}
                    </div>
                      <div className='row mb-2'>
                          <div className='form-label' forhtml='clave'>
                              Contraseña
                          </div>
                          <div className="input-group">
                              <input type="password" className="form-control" 
                                     placeholder="Ingresar contraseña" id='clave'
                                     name='clave' aria-describedby="button-addon1" 
                                     onChange={handleChange}
                                     onBlur={handleBlur} 
                                     required/>
                              <button className="btn btn-secondary" type="button" 
                                      id="button-addon1" onClick={mostrarC}>
                              <i className="fa-solid fa-eye"></i>
                              </button>
                          </div>
                          {errors.clave ? <div className='alert alert-danger mt-2'>{errors.clave}</div> : <></>}
                      </div>
                    <div className='row mb-4'>
                        <div className='form-label' forhtml='cclave'>
                            Confirmar Contraseña
                        </div>
                        <div className="input-group">
                              <input type="password" className="form-control" 
                                     placeholder="Ingresar confirmación" id='cclave'
                                     name='cclave' aria-describedby="button-addon2" 
                                     onChange={handleChange}
                                     onBlur={handleBlur} 
                                     required/>
                              <button className="btn btn-secondary" type="button" 
                                      id="button-addon2" onClick={mostrarC2}>
                              <i className="fa-solid fa-eye"></i>
                              </button>
                        </div>
                        {errors.cclave?<div className='alert alert-danger mt-2'>{errors.cclave}</div>:<></>}
                    </div>
                      <div className='row mb-4'>
                          <div className='form-label'>
                              Tipo de Usuario
                          </div>
                          <div className='row'>
                              <div className='col'>
                                  <label className="form-label me-1" forhtml="tipoU1" >admin</label>
                                  <input className="form-check-input" type='radio' id='tipoU1' name='tipoU'
                                      onChange={handleChange} onBlur={handleBlur}
                                      value="admin" 
                                      />
                              </div>
                              <div className='col'>
                                  <label className="form-label me-1" forhtml="tipoU2" >user</label>
                                  <input className="form-check-input" type='radio' id='tipoU2' name='tipoU'
                                      onChange={handleChange} onBlur={handleBlur}
                                      value="user"  
                                      />
                              </div>
                          </div>
                          {errors.tipoU?<div className='alert alert-danger mt-2'>{errors.tipoU}</div>:<></>}
                      </div>
                    <div className='row mt-2'>
                        <div className='col-6 mx-auto mt-2'>
                        <button type='submit' className='btn btn-primary' onClick={handleSubmit}>Enviar</button>
                        </div>
                    </div>
                    {mensajeEBD!=''?<div className='alert alert-danger mt-2'>{mensajeEBD}</div>:<></>}
                </form>
            </div>
        </div>
    </div>
  )
}
