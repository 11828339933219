import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../style/Login.css'
import img1 from '../../Imagenes/User.jpg'
import { useFormLogin } from '../../Hooks/useFormLogin'
import {ButtonHome} from '../../Home/components/ButtonHome'
import { Navigate } from "react-router-dom"
import Cookies from 'universal-cookie'
import axios from "axios";


//var baseURL = "https://localhost:8080/usuarios";
var baseURL ="https://monitortuga.monitoreomarinocosterord.com:8442/usuarios"

const initialForm = {

    user: '',
    pw: '',
  
  };

export const Login = () => {

    const [post, setPost] = useState({});
    const [mensajeI, setMensajeI] = useState('');
    const [userlogin, setUserlogin]=useState({});

    useEffect(() => {

        
        if(post!=null && post!=undefined && Object.entries(post).length!=0){

            //console.log(post.data);

            if(post.data=='usuario no existe'){

                setMensajeI('Usuario no existe.');

            }else if(post.data == 'clave incorrecta'){

                setMensajeI('Contraseña incorrecta');
            }else{

                axios
                .get(`${baseURL}/Id/${post.data}`, form)
                .then((response) => {
                    
                    setUserlogin(response.data);
    
                }).catch({/*error => console.log(error)*/});
    
            }

        }

    }, [post])
    

    const validateForm = () => {

        let errors = {};
        let regexUser = /^[0-9a-zA-Z]+$/;
        let regexPW = /^[0-9a-zA-Z]+$/;

        if (!form.user.trim())
            errors.user = "El campo usuario es requerido.";
        else {

            if (!regexUser.test(form.user.trim()))
                errors.user = 'Solo se permite letras y números.';
        }

        if (!form.pw.trim())
            errors.pw = "La contraseña es requerida.";
        else {

            if (!regexPW.test(form.pw.trim()))
                errors.pw = 'Solo se permite letras y números.';
        }

        return errors;
    };

    const {
        form,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormLogin(initialForm, validateForm,setPost);

    const mostrarC=()=>{

        let clave=document.getElementById('clave');
    
        if (clave.getAttribute('type') == 'password')
            clave.setAttribute('type', 'text');
        
        else
            clave.setAttribute('type', 'password');
            
    }

    if(Object.entries(userlogin).length!=0){ 

        const cookies=new Cookies();
        cookies.set('IdU',userlogin.id,{path:'/'});
        cookies.set('nombreU',userlogin.nombre,{path:'/'});
        cookies.set('tipoU',userlogin.tipoU,{path:'/'});
        
        return <Navigate to='/'/>
    }

    return (
        <div className='container p-4 '>
            <ButtonHome/>
            <div className='col-8 mx-auto border border-white rounded '>
                <img src={img1} alt='No se encuentra imagen' 
                      id='imagen-login'
                      className='shadow p-1 mb-2 bg-body-tertiary rounded mt-4'/>
                <h4>Login</h4>
                <form >
                    <div className='row mb-4'>
                        <div className='col-8 mx-auto'>
                            <div className="input-group input-group">
                                <span className="input-group-text" id="usuario">
                                    <i className="fa-solid fa-user"></i> &nbsp;
                                    Usuario&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <input type="text" className="form-control"
                                        placeholder='Ingresar usuario' 
                                        aria-label="Sizing example input"
                                        aria-describedby="usuario" id='user' name='user' 
                                        value={form.user} onChange={handleChange} onBlur={handleBlur} required/>
                            </div>
                            {errors.user?<div className='alert alert-danger mt-2'>{errors.user}</div>:<></>}
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-8 mx-auto'>
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="fa-solid fa-key"></i> &nbsp;
                                Contraseña
                            </span>
                              <input type="password" className="form-control" 
                                     placeholder="Ingresar contraseña" id='clave'
                                     name='pw' aria-describedby="button-addon1" 
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     value={form.pw} 
                                     required/>
                              <button className="btn btn-secondary" type="button" 
                                      id="button-addon1" onClick={mostrarC}>
                              <i className="fa-solid fa-eye"></i>
                              </button>
                          </div>
                            {errors.pw?<div className='alert alert-danger mt-2'>{errors.pw}</div>:<></>}
                        </div>
                    </div>
                    <div className='row mb-4 '>
                        <div className='col-6 mx-auto'>
                            <button className='btn btn-dark me-2' onClick={handleSubmit}>
                                Ingresar
                            </button>
                            <button className='btn btn-dark'>
                                <Link style={{ color: "white" }} to='/'>
                                    Cancelar
                                </Link>
                            </button>
                        </div>
                        <div className='col-8 mx-auto'>
                        {mensajeI==''?<></>:<div className='alert alert-danger mt-4'>{mensajeI}</div>}
                        </div>
                    </div>
                </form>
            </div>
            <br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/>
            <br/><br/><br/>
        </div>
    )
}
