import React from 'react'
import '../style/Ayuda.css'

export const Ayuda = ({mensaje}) => {
    return (

        <div id='ayuda-div' className="alert alert-info" role="alert">
            <p>{mensaje}</p>
        </div>
  )
}
