export default [
    {
      tipo: "texto",
      campo: "Hora"
    },
    {
      tipo:'ayuda',
      mensaje:'Actividad: for: forrajeo, ap: apareamiento, '+
              'des: desplazamiento, rep: reposo'
    },
    {
      tipo: "texto",
      campo: "Actividad"
    },
    {
      tipo: "titulo1",
      campo: "Coordenadas del nido"
    },
    {
      tipo: "texto",
      campo: "Latitud"
    },
    {
      tipo: "texto",
      campo: "Longitud"
    },
    {
      tipo: "titulo1",
      campo: "Número de nido"
    },
    {
      tipo: "texto",
      campo: "Num_nido"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    },
    {
      tipo:'ayuda',
      mensaje:'Especie: tortuga verde o blanca (Chelonia mydas), '+
              'el carey (Eretmochelys imbricata), caguama o caguamo (Caretta caretta), '+
              'tortuga golfina (Lepidochelys olivacea), el tinglar, (Dermochelys coriacea)'
    },
    {
      tipo: "texto",
      campo: "Especie"
    },
    {
      tipo: "titulo1",
      campo: "Morfometría LSCC Largo curvo estándar del carapacho"
    },
    {
      tipo: "texto",
      campo: "Morfometria_LSCC"
    },
    {
      tipo: "titulo1",
      campo: "Morfometría ACC Ancho curvo del carapacho"
    },
    {
      tipo: "texto",
      campo: "Morfometria_ACC"
    },
    {
      tipo: "titulo1",
      campo: "Estado físico de la tortuga o individuo capturado o daño presente"
    },
    {
      tipo:'ayuda',
      mensaje:'cic: cicatrices, pr: propelazos, her: heridas, mor: mordidas, fib: fibropapilomas, '+
              'presencia de tumores (fibropapilo - mas), según el protocolo de fibropa - pilomatosis, '+
              'cicatrices por propelas, heridas por cuerdas y herida con arpón.'
    },
    {
      tipo: "texto",
      campo: "Estado_fisico"
    },
    {
      tipo: "titulo1",
      campo: "Ancho de cabeza (AC)"
    },
    {
      tipo: "texto",
      campo: "Ancho_cabeza"
    },
    /*{
      tipo: "titulo1",
      campo: "Zona balizas: Ubicación del nido respecto a las balizas"
    },
    {
      tipo: "texto",
      campo: "Zona_balizas"
    },*/
    {
      tipo: "titulo1",
      campo: "Número o conteo de huevos"
    },
    {
      tipo: "texto",
      campo: "Num_huevos"
    },
    {
      tipo: "titulo1",
      campo: "Nido en perfil: Ubicación del nido en el perfil de playa"
    },
    {
      tipo:'ayuda',
      mensaje:'a: anteplaya, b: posplaya (berma), c: duna'
    },
    {
      tipo: "texto",
      campo: "Nido_perfil"
    },
    {
      tipo: "titulo1",
      campo: "Temperatura del nido"
    },
    {
      tipo: "texto",
      campo: "Temp_nido"
    },
    {
      tipo: "titulo1",
      campo: "Profundidad del nido"
    },
    {
      tipo: "texto",
      campo: "Prof_nido"
    },
    {
      tipo: "titulo1",
      campo: "Marca o Tags"
    },
    {
      tipo: "texto",
      campo: "Marca_tags"
    },
    {
      tipo: "titulo1",
      campo: "Observaciones"
    }, 
    {
      tipo: "textoArea",
      campo: "Observaciones"
    },
    {
      tipo:"espacio",
      campo:"espacio"
    }
  ]